import { useEffect, useState } from 'react';
import Form, { FormProps } from '../form/Form';

interface Props<T> {
  steps: FormProps<T>[];
  stepIndex: number;
  setStepIndex: (stepIndex: number) => void;
}

const StepForm = <T extends object>({ steps, stepIndex, setStepIndex }: Props<T>) => {
  const [formVals, setFormVals] = useState<T>({} as T);
  const handleOnSubmit = async (values: T) => {
    const selectedProps = steps[stepIndex];
    setFormVals({ ...formVals, [stepIndex]: values });
    selectedProps.onSubmit && (await selectedProps.onSubmit({ ...formVals, [stepIndex]: values }));
    setStepIndex(stepIndex + 1);
  };

  return (
    <>
      {stepIndex <= steps.length && <Form {...steps[stepIndex]} onSubmit={handleOnSubmit} key={stepIndex} />}
    </>
  );
};

export default StepForm;
