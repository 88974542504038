import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { FormProps } from '../../../components/generic/form/Form';
import Button from '../../../components/inputs/buttons/Button';
import { categoryService } from '../../../service/category.service';
import { BUTTON_TYPE, FORM_INPUT_TYPE, ICategories, IFormInput } from '../../../types';

const inputs: IFormInput[] = [];
export const addCategoriesForm = (
  navigate: NavigateFunction,
  farmId: number | string,
): FormProps<ICategories>[] => {
  const goBack = () => navigate(`/farm/${farmId}/categories`);

  const handleNewInput = () => {
    setForm((prev) => {
      const newInputs = [
        ...(form[1].inputs || []),
        {
          id: `subCategory${prev[1].inputs?.length || 0}`,
          label: 'sub categoria',
          placeholder: 'Sub Categoria',
          required: true,
          validate: (value: string) => !!value.length,
          errorMessage: 'Porfavor ingresar el nombre de sub categoria',
          type: FORM_INPUT_TYPE.TEXT,
        },
      ];
      prev[1].inputs = newInputs;
      return [...prev];
    });
  };
  const [form, setForm] = useState<FormProps<ICategories>[]>([
    {
      inputs: [
        {
          id: 'name',
          label: 'Nombre de categoria',
          placeholder: 'Nombre',
          required: true,
          validate: (value: string) => !!value.length,
          errorMessage: 'Porfavor ingresar el nombre de categoria',
        },
      ],
      buttons: [
        {
          className: 'secondary',
          text: 'cancelar',
          type: BUTTON_TYPE.BUTTON,
          onClick: () => {
            goBack();
          },
        },
        {
          text: 'continuar',
          type: BUTTON_TYPE.SUBMIT,
        },
      ],
      onSubmit: async (data: any) => {
        const isCategoryDb = await categoryService.getAll({ ...data[0], farmId, isExactName: true });
        if (isCategoryDb.data?.length) throw new Error('Ya existe una Categoria con ese nombre');
      },
    },
    {
      inputs,
      children: (
        <Button onClick={handleNewInput} text="agregar sub categoria" className="add-sub-categories" />
      ),
      buttons: [
        {
          className: 'secondary',
          text: 'cancelar',
          type: BUTTON_TYPE.BUTTON,
          onClick: () => {
            goBack();
          },
        },
        {
          text: 'continuar',
          type: BUTTON_TYPE.SUBMIT,
        },
      ],
      onSubmit: async (data: any) => {
        const transformedData = {
          ...data[0],
          subCategories: Object.values(data[1]),
          farmId,
        };
        await categoryService.create(transformedData);
        goBack();
      },
    },
  ]);

  return form;
};
