import { useState } from 'react';
import ListWithChildrenLine from '../../../components/generic/listhWithChildren/ListWithChildrenLine';
import Separator from '../../../components/generic/separator/Separator';
import HorizontalContainer from '../../horizontalContainer/HorizontalContainer';
import Button from '../../inputs/buttons/Button';
import ButtonLink from '../../inputs/buttons/LinkButton';
import RedButton from '../../inputs/buttons/Redbutton';
import ConfirmModal from '../../modals/ConfirmModal';
import Card from './Card';

interface Props<T> {
  data: T;
  handleDelete?: (id: string | number) => Promise<void>;
}
const SubCategoryCard = <
  T extends {
    name: string;
    value?: number | string;
    subCategories: { name: string; animalsCount?: number | string; animals?: { quantity: number }[] }[];
    id?: string;
  },
>({
  data,
  handleDelete,
}: Props<T>) => {
  const [showDeleteModal, setShowDeletModal] = useState(false);
  const handleChilds = () => {
    let total = 0;
    const children = data.subCategories?.map(({ name, animalsCount = 0, animals = [] }, i) => {
      const totalPerSubCategory = animals?.reduce((acc, a) => (acc += a.quantity), 0);
      if (animalsCount) total += parseInt(animalsCount.toString(), 10);
      else total += totalPerSubCategory;
      return <ListWithChildrenLine name={name} value={animalsCount || totalPerSubCategory} key={name} />;
    });
    // setQuantity(total);
    return (
      <>
        <div className="title-container">
          <h2>{data.name}</h2>
          <h2>{total}</h2>
        </div>
        <Separator />
        {children}
      </>
    );
  };

  const handleSetModal = () => {
    setShowDeletModal((s) => !s);
  };

  const handleDeleteConfirmation = async () => {
    try {
      handleDelete && data.id && (await handleDelete(data.id));
    } catch (e) {
      console.log(e);
      //should be alarm on error TODO
    } finally {
      handleSetModal();
    }
  };
  return (
    <>
      <ConfirmModal
        text={`Estas seguro que queres eliminar la categoria: ${data.name}`}
        title="Eliminar Potrero"
        showModal={showDeleteModal}
      >
        <HorizontalContainer>
          <RedButton onClick={handleDeleteConfirmation}>Eliminar</RedButton>
          <Button onClick={handleSetModal}>Cancelar</Button>
        </HorizontalContainer>
      </ConfirmModal>

      <Card key={'movements'} className="wide-card categories-card">
        {handleChilds()}
        <HorizontalContainer className="sub-cat-hidden-bottom">
          <RedButton onClick={handleSetModal}>Eliminar</RedButton>
          <ButtonLink to={`${data.id}/edit`}>Editar</ButtonLink>
        </HorizontalContainer>
      </Card>
    </>
  );
};

export default SubCategoryCard;
