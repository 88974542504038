import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CardLinkAdd from '../../../components/containers/card/CardLinkAdd';
import CardList from '../../../components/containers/card/CardList';
import FarmLayout from '../../../components/farmLayout/FarmLayout';
import Button from '../../../components/inputs/buttons/Button';
import LinkButton from '../../../components/inputs/buttons/LinkButton';
import SearchInput from '../../../components/inputs/searchInput/SearchInput';
import DraggableList, { IDraggableElement } from '../../../components/lists/draggable/DraggableList';
import { animalColumnService, farmService, paddockService, saidService } from '../../../service';
import { subCategoryService } from '../../../service/subCategory.service';
import { useAppSelector } from '../../../store';
import { IAnimalColumn, IPaddock, ISaid, ISubCategory } from '../../../types';
import './Paddock.css';
import PaddockCard from './PaddockCard';

const Paddock = () => {
  const { id } = useParams();
  const farm = useAppSelector((state) => state.farm);

  const [pagination, setPagination] = useState({ page: 1, totalPages: 1, take: 10 });
  const [paddock, setPaddock] = useState<IPaddock[]>([]);
  const [paddocksChilds, setPaddocksChilds] = useState<IDraggableElement[]>([]);
  const [categories, setCategories] = useState<ISubCategory[] | null>(null);
  const [saids, setSaids] = useState<ISaid[] | null>(null);
  const [activeColumns, setActiveColumns] = useState<IAnimalColumn[]>([]);
  const getPaddock = async (name = '') => {
    const data = await paddockService.getAll({ farmId: id, name, ...pagination });

    const transformedData = data.data.map((p) => {
      let value = 0;
      const subCategories = p.animals.map((a) => {
        value += a.quantity;
        return { animalsCount: a.quantity, name: a.subCategory?.name };
      });
      return { ...p, subCategories, value, animals: p.animals };
    });
    setPaddock(transformedData as unknown as IPaddock[]);
    setPagination((p) => ({ ...pagination, totalPages: Math.ceil(data.total / p.take) }));
  };

  useEffect(() => {
    getPaddock();
  }, [pagination.page, pagination.take, id]);

  const getCategories = async () => {
    const { data } = await subCategoryService.getAll({ farmId: id });
    setCategories(data);
  };

  const getSaids = async () => {
    const { data } = await saidService.getAll({ farmId: id });
    setSaids(data);
  };

  const getAnimalColumns = async () => {
    const { data } = await animalColumnService.getAll();
    setActiveColumns(data);
  };

  useEffect(() => {
    getAnimalColumns();
    getCategories();
    getSaids();
  }, []);

  const updatePosition = async (id: number | string, position: number) => {
    await paddockService.updatePosition(id, position);
    getPaddock();
  };

  const updateDragableChilds = () => {
    if (!categories || !saids) return;
    const elems = paddock.map((p) => {
      return {
        id: p.id.toString(),
        components: (
          <PaddockCard
            key={p.id}
            paddock={p}
            getPaddocks={getPaddock}
            activeColumns={activeColumns}
            options={categories?.map((c) => ({ value: c.id, label: c.name }))}
            saidOptions={saids?.map((s) => ({ value: s.id.toString(), label: s.name }))}
          />
        ),
      };
    });
    setPaddocksChilds(elems);
  };

  const handleDownload = async () => {
    if (!id) return;
    const { data } = await farmService.download(id);
    const anchor = document.createElement('a');
    anchor.href = data.url;
    anchor.target = '_blank';
    anchor.download = `${data.name}.${data.extension}`;

    // Trigger a click event on the anchor element
    anchor.click();

    // Clean up: remove the temporary anchor
    anchor.remove();
  };

  useEffect(() => {
    updateDragableChilds();
  }, [paddock, categories, saids]);
  return (
    <FarmLayout
      className="dashboard paddocks"
      title={farm?.name || ''}
      description="Categorias"
      headerClassName="paddocks-header"
      headerChildren={
        <>
          <SearchInput handleSearch={getPaddock} />
          <LinkButton className="create-movement-link" to={`/farm/${id}/movements/create`}>
            {' '}
            Crear Movimientos
          </LinkButton>
          <Button className="create-movement-link" onClick={handleDownload}>
            {' '}
            Descargar
          </Button>
        </>
      }
    >
      {categories && saids && (
        <CardList
          className={'farm-dashboard-list paddocks-list'}
          pagination={pagination}
          setPagination={setPagination}
        >
          <DraggableList elements={paddocksChilds} updatePosition={updatePosition} />
          <CardLinkAdd
            title={'Agregar Potrero'}
            text={
              'Agregar nuevos potreros para mantener un registro de la cantidad de animales en cada uno de ellos'
            }
            cardType={'ultra-wide'}
          />
        </CardList>
      )}
    </FarmLayout>
  );
};

export default Paddock;
