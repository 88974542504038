import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Slogan from '../../components/branding/Slogan';
import CardWithImage from '../../components/containers/cardWithImage/CardWithImage';
import VerticalContainer from '../../components/containers/verticalContainer/VerticalContainer';
import Logo from '../../components/generic/Logo';
import StepForm from '../../components/generic/stepForm/StepForm';
import HorizontalContainer from '../../components/horizontalContainer/HorizontalContainer';
import GoogleButton from '../../components/inputs/buttons/googleButton/GoogleButton';
import { IRegister } from '../../types/generic/login.types';
import { registerFields } from './Register.field';
import './Register.css';

const Register = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { registerSetpForm } = registerFields(navigate);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (state?.step) setStepIndex(state.step);
  }, []);
  return (
    <CardWithImage className="login-container register">
      <Logo />
      <Slogan secondarytext="¡Bienvenido! Completa los siguientes datos para registrarte." />
      <VerticalContainer>
        <StepForm<IRegister> steps={registerSetpForm} stepIndex={stepIndex} setStepIndex={setStepIndex} />
        {stepIndex === 0 && (
          <>
            <HorizontalContainer className="optional">
              <h5>O</h5>
            </HorizontalContainer>
            <HorizontalContainer className="center">
              <GoogleButton />
            </HorizontalContainer>
          </>
        )}
      </VerticalContainer>
    </CardWithImage>
  );
};

export default Register;
