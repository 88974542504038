import { LogoHead, LogoBottom } from '../../../images';
import './BlurComponentLoader.css';

interface IProps {
  className?: string;
}

const BlurComponentLoader = ({ className = '' }: IProps) => {
  return (
    <div className={`blur-loader-container ${className}`}>
      <div className="blur-loader-img-container">
        <div className="blur-animation-container">
          <img src={LogoHead} alt="logo-head" className="blur-loader-img head" />
          <img src={LogoBottom} alt="logo-head" className="blur-loader-img bottom" />
        </div>
      </div>
    </div>
  );
};
export default BlurComponentLoader;
