import { AxiosError } from 'axios';
import { useState } from 'react';
import Form from '../../../components/generic/form/Form';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import { saidService } from '../../../service';
import { ISaid } from '../../../types';
import { addSaidsButton, addSaidsFields } from './AddSaids.fields';

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  getSaids: () => Promise<void>;
}

const AddSaids = ({ showModal, setShowModal, getSaids }: IProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const buttons = addSaidsButton;
  buttons[0] = { ...buttons[0], onClick: () => setShowModal(false) };

  const onSubmit = async (values: ISaid) => {
    try {
      setErrorMessage(null);
      await saidService.create(values);
      await getSaids();
      setShowModal(false);
    } catch (err: unknown | AxiosError<{ message: string }>) {
      if (err instanceof AxiosError<{ message: string }>)
        setErrorMessage(
          err?.response?.status != 500 && err?.response?.data?.message
            ? err?.response?.data?.message
            : 'Error agregando el dicose',
        );

      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }
  };
  return (
    <ConfirmModal
      text="Agregar dicoses para poder vincular a los animales"
      title="Agregar Dicose"
      showModal={showModal}
    >
      <Form inputs={addSaidsFields} buttons={buttons} onSubmit={onSubmit}>
        {errorMessage && (
          <p className="error-message" style={{ marginBottom: '10px' }}>
            {errorMessage}
          </p>
        )}
      </Form>
    </ConfirmModal>
  );
};

export default AddSaids;
