import { axios } from '../handlers';
import { IContact } from '../pages/contactUs/Contact.field';
import { ENDPOINTS } from './endpoints';

export class ContactUsService {
  endpoint: () => string;

  constructor() {
    this.endpoint = ENDPOINTS.CONTACT_US;
  }
  post = async (data: IContact): Promise<{ data: IContact; message: string }> =>
    (await axios.post(this.endpoint(), data)).data;
}

export const contactUsService = new ContactUsService();
