import { CredentialResponse, GoogleLogin, useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { authService } from '../../../../service';
import './GoogleButton.css';

const GoogleButton = () => {
  const navigate = useNavigate();

  const handleOnSuccess = async (response: CredentialResponse) => {
    if (!response.credential) return;
    const res = await authService.googleOAuth(response.credential);
    navigate('/dashboard', { state: { user: res.data } });
  };

  const handleOnError = () => {
    console.error('ups');
  };
  useGoogleOneTapLogin({
    onSuccess: handleOnSuccess,
    onError: handleOnError,
  });

  return (
    <GoogleLogin
      onSuccess={handleOnSuccess}
      onError={() => {
        console.log('Login Failed');
      }}
      useOneTap
    />
  );
};

export default GoogleButton;
