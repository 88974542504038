import { useState } from 'react';
import Card from '../../components/containers/card/Card';
import CenterContainer from '../../components/containers/centeredContainer/CenterContainer';
import VerticalContainer from '../../components/containers/verticalContainer/VerticalContainer';
import Form from '../../components/generic/form/Form';
import Header from '../../components/header/Header';
import { contactUsService } from '../../service/contactUs.service';
import { IContact, contactButtons, contactFields } from './Contact.field';
import './ContactUs.css';

const ContactUs = () => {
  const [success, setSuccess] = useState(false);
  const onSubmit = async (values: IContact) => {
    await contactUsService.post(values);
    setSuccess(true);
  };
  return (
    <VerticalContainer className="landing-container contact-container">
      <Header />
      <CenterContainer>
        <Card>
          <h2>Contactanos</h2>
          <Form<IContact> inputs={contactFields} buttons={contactButtons()} onSubmit={onSubmit} />
        </Card>

        {success && (
          <Card className="success">
            <p>Mensaje enviado correctamente</p>
          </Card>
        )}
      </CenterContainer>
    </VerticalContainer>
  );
};

export default ContactUs;
