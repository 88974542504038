import CardLink from '../../../components/containers/card/CardLink';
import Separator from '../../../components/generic/separator/Separator';

const FarmStatsCard = () => {
  return (
    <CardLink to={'movements'} key={'movements'} className="wide-card disabledFirstRealse">
      <div className="title-container">
        <h2>Estadisticas</h2>
      </div>
      <Separator />
      <div className="stats-container">
        <div className="stats">
          <h4>Nuevos Animales</h4>
          <p>1500</p>
        </div>
        <div className="stats">
          <h4>Animales vendidos</h4>
          <p>10</p>
        </div>
        <div className="stats">
          <h4>Animales Muertos</h4>
          <p>8</p>
        </div>
        <div className="stats">
          <h4>Animales perdidos</h4>
          <p>7s</p>
        </div>
      </div>
    </CardLink>
  );
};

export default FarmStatsCard;
