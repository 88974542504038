export interface IFormInput<T = HTMLInputElement> {
  id: string;
  label?: string;
  defaultValue?: string;
  placeholder?: string;
  required?: boolean;
  validate?: (value: string) => boolean;
  errorMessage?: string;
  onChange?: (e: React.ChangeEvent<T>) => void;
  ref?: React.RefObject<HTMLInputElement>;
  type?: FORM_INPUT_TYPE;
  isNotValid?: boolean;
  touched?: boolean;
  onTouched?: (e: React.FocusEvent<T>) => void;
  className?: string;
  options?: { value: string; label: string }[];
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  min?: number;
  max?: number;
}

export interface IFormButton {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  text?: string;
  children?: React.ReactNode;
  type?: BUTTON_TYPE;
  disabled?: boolean;
}

export enum FORM_INPUT_TYPE {
  TEXT = 'text',
  TEXT_AREA = 'textarea',
  PASSWORD = 'password',
  NUMBER = 'number',
  URL = 'url',
  DATE = 'date',
  SELECT = 'select',
  SELECT_AND_QUANTITY = 'select-and-quantity',
  BOOLEAN = 'boolean',
}

export enum BUTTON_TYPE {
  SUBMIT = 'submit',
  RESET = 'reset',
  BUTTON = 'button',
}
