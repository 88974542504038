import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchInput from '../../components/inputs/searchInput/SearchInput';
import Mainbody from '../../components/layout/mainbody/Mainbody';
import { farmService } from '../../service/farm.service';
import { useAppDispatch, useAppSelector, userActions } from '../../store';
import { IFarm, PaginatedResponse } from '../../types';
import './Dashboard.css';
import DashboardList from './DashboardList';

const Dashboard = () => {
  const [farms, setFarms] = useState<IFarm[]>([]);
  const [farmsFilter, setFarmsFilter] = useState<IFarm[]>([]);
  const [filterVal, setFilterFal] = useState<string>('');
  const { state } = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const handleFarms = async () => {
    const response = await farmService.getAll();
    setFarms(response.data);
  };
  useEffect(() => {
    if (state?.user?.id)
      dispatch(
        userActions.setUser({ ...state.user, refreshToken: state.user.refreshToken || state.refreshToken }),
      );
    if (!user?.id && !state?.user?.id) navigate('/login', { replace: true });

    handleFarms();
  }, []);

  const handleSearch = (value: string) => {
    setFilterFal(value);
  };

  useEffect(() => {
    setFarmsFilter(farms.filter((farm) => farm.name.toLowerCase().includes(filterVal.toLowerCase())));
  }, [farms, filterVal]);

  return (
    <>
      <Mainbody className="dashboard" headerChildren={<SearchInput handleSearch={handleSearch} />}>
        <DashboardList farms={farmsFilter} getAllFarms={handleFarms} />
      </Mainbody>
    </>
  );
};

export default Dashboard;
