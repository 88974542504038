import { IUserSlice } from './../types/entities.types';
import { ENDPOINTS } from './endpoints/endpoints';
import { IUser } from '../types';
import BaseService from './base.service';
import { axios } from '../handlers';

class UserService extends BaseService<IUser> {
  constructor() {
    super(ENDPOINTS.USERS);
  }

  addUserToFarm = async (email: string) => {
    return axios.post(ENDPOINTS.USER_FARMS(), { email });
  };

  deleteUserFromFarm = async (id: string | number) => {
    return axios.delete(`${ENDPOINTS.USER_FARMS()}/${id}`);
  };
}
export const userService = new UserService();
export default userService;
