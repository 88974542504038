import { axios } from '../handlers';
import { DeepPartial, IObservations } from '../types';
import { ENDPOINTS } from './endpoints';

class ObservationService {
  endpoint = (paddockId: string | number) => {
    return ENDPOINTS.OBSERVATIONS().replace(':paddockId', paddockId.toString());
  };

  public delete = async (id: string | number, paddockId: string | number) => {
    return await axios.delete(`${this.endpoint(paddockId)}/${id}`);
  };

  public update = async (element: DeepPartial<IObservations>, paddockId: string | number) => {
    return await axios.put(`${this.endpoint(paddockId)}/${element.id}`, element);
  };

  public create = async (element: IObservations) => {
    return await axios.post(this.endpoint(element.paddockId), element, { withCredentials: true });
  };
}
export const observationService = new ObservationService();
