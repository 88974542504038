import Card from '../containers/card/Card';
import CenterContainer from '../containers/centeredContainer/CenterContainer';
import './Modal.css';

type Props = {
  children: React.ReactNode;
  className?: string;
  isClosing?: boolean;
};

const Modal = ({ children, className = '', isClosing = false }: Props) => (
  <CenterContainer className={`modal ${isClosing ? 'closing' : ''}`}>
    <Card className={`${className}`}>{children}</Card>
  </CenterContainer>
);

export default Modal;
