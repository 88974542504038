import { useNavigate } from 'react-router-dom';
import { logout } from '../../../../images/icons';
import { authService } from '../../../../service';
import { generalActions, useAppDispatch, useAppSelector, userActions } from '../../../../store';
import Button from '../Button';

interface Props {
  className?: string;
}
const LogoutButton: React.FC<Props> = ({ className = '' }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const handleLogout = async () => {
    await authService.logout();
    dispatch(generalActions.setLoading(false));
    navigate('/login');
  };
  return (
    <Button onClick={handleLogout} className={className}>
      <img src={logout} alt="logout icon" />
      Cerrar Session
    </Button>
  );
};

export default LogoutButton;
