import Button from '../../inputs/buttons/Button';
import './Card.css';

export interface Props {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}
const CardButton = ({ children, className = '', onClick }: Props) => {
  return (
    <Button className={`main-card ${className}`} onClick={onClick}>
      <div className="content">{children}</div>
    </Button>
  );
};

export default CardButton;
