import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slogan from '../../components/branding/Slogan';
import CardWithImage from '../../components/containers/cardWithImage/CardWithImage';
import VerticalContainer from '../../components/containers/verticalContainer/VerticalContainer';
import Form from '../../components/generic/form/Form';
import Logo from '../../components/generic/Logo';
import HorizontalContainer from '../../components/horizontalContainer/HorizontalContainer';
import GoogleButton from '../../components/inputs/buttons/googleButton/GoogleButton';
import CheckBoxInput from '../../components/inputs/checkbox/CheckBoxInput';
import { authService } from '../../service';
import { generalActions, useAppDispatch, userActions } from '../../store';
import { ILogin } from '../../types/generic/login.types';
import './Login.css';
import { loginButtons, loginFields } from './Login.fields';
const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = async (values: ILogin) => {
    const res = await authService.login(values);
    navigate('/dashboard', { state: { user: res.data, refreshToken: res.refreshToken } });
  };
  useEffect(() => {
    dispatch(userActions.logout());
  }, []);

  const handleCheckBoxChange = (e: any) => {
    dispatch(generalActions.setPartial({ rememberMe: e.target.checked }));
  };
  const buttons = loginButtons(navigate);
  return (
    <CardWithImage className="login-container">
      <Logo />
      <Slogan secondarytext="Bienvenido de nuevo, ingrese con su usuario" />
      <VerticalContainer>
        <Form<ILogin> inputs={loginFields} buttons={buttons} onSubmit={onSubmit}>
          <HorizontalContainer className="forget-password">
            <CheckBoxInput
              label="Recuérdame"
              id="rememberme"
              type="checkbox"
              onClick={handleCheckBoxChange}
            />
            <a href="http://">¿Olvidaste la Contraseña?</a>
          </HorizontalContainer>
        </Form>
        <HorizontalContainer className="optional">
          <h5>O</h5>
        </HorizontalContainer>
        <HorizontalContainer className="center">
          <GoogleButton />
        </HorizontalContainer>
      </VerticalContainer>
    </CardWithImage>
  );
};

export default Login;
