interface Props {
  movements?: { message: string }[];
}
const DashboardMovements = ({ movements = [] }: Props) => {
  return (
    <div className="movements">
      <h4>Moviminentos Recientes</h4>
      <ul>
        {movements?.map((movement, i) => (
          <li key={i}>{movement.message}</li>
        ))}
      </ul>
    </div>
  );
};

export default DashboardMovements;
