import { IFormInput, FORM_INPUT_TYPE, BUTTON_TYPE } from '../../types';

export const getFields = (setAddFarm: React.Dispatch<React.SetStateAction<boolean>>) => {
  const inputs: IFormInput[] = [
    {
      id: 'name',
      label: 'Nombre de estancia',
      required: true,
      validate: (value: string) => !!value.length,
      errorMessage: 'Porfavor ingrese el nombre',
      type: FORM_INPUT_TYPE.TEXT,
    },
  ];

  const buttons = [
    {
      type: BUTTON_TYPE.SUBMIT,
      children: 'Agregar',
    },
    {
      text: 'Cancelar',
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        setAddFarm(false);
      },
      className: 'secondary',
    },
  ];

  return { inputs, buttons };
};
