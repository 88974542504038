import { BUTTON_TYPE, FORM_INPUT_TYPE, IFormButton } from '../../../types';

export const addSaidsFields = [
  {
    id: 'name',
    label: 'Nombre',
    placeholder: 'Ingresar nombre',
    required: true,
    validate: (value: string) => !!value.length,
    errorMessage: 'El nombre es requerido',
    type: FORM_INPUT_TYPE.TEXT,
  },

  {
    id: 'serial',
    label: 'Identificador',
    placeholder: 'Ingresar identificador',
    required: true,
    validate: (value: string) => !!value.length,
    errorMessage: 'El identificador es requerido',
    type: FORM_INPUT_TYPE.TEXT,
  },
];

export const addSaidsButton: IFormButton[] = [
  {
    type: BUTTON_TYPE.RESET,
    children: 'Cancelar',
    className: 'red',
  },
  {
    type: BUTTON_TYPE.SUBMIT,
    children: 'Agregar',
  },
];
