import { FORM_INPUT_TYPE, BUTTON_TYPE, IFormButton } from '../../types';
import { EMAIL_REGEXP } from '../../types/regexp';

export const contactFields = [
  {
    id: 'name',
    label: 'Nombre',
    placeholder: 'Nombre',
    required: true,
    validate: (value: string) => !!value.length,
    errorMessage: 'El nombre es requerido',
    type: FORM_INPUT_TYPE.TEXT,
  },

  {
    id: 'email',
    label: 'Email',
    placeholder: 'Email',
    required: true,
    validate: (value: string) => EMAIL_REGEXP.test(value),
    errorMessage: 'El email es requerido',
    type: FORM_INPUT_TYPE.TEXT,
  },
  {
    id: 'subject',
    label: 'Sujeto',
    placeholder: 'Sujeto',
    required: true,
    validate: (value: string) => !!value.length,
    errorMessage: 'El Sujeto es requerido',
    type: FORM_INPUT_TYPE.TEXT,
  },
  {
    id: 'message',
    label: 'Mensaje',
    placeholder: 'Agrega tu mensaje aqui',
    required: true,
    validate: (value: string) => !!value.length,
    errorMessage: 'El mensaje es requerido',
    type: FORM_INPUT_TYPE.TEXT_AREA,
  },
];

export const contactButtons = (): IFormButton[] => [
  {
    type: BUTTON_TYPE.SUBMIT,
    children: 'Enviar',
  },
];

export interface IContact {
  email: string;
  name: string;
  subject: string;
  message: string;
}
