import { IFormInput } from '../../types';
import GenericInput from './GenericInput';
import './InputIcon.css';
interface InputIcon extends IFormInput {
  img?: string;
}
const InputIcon: React.FC<InputIcon> = (props) => {
  return (
    <div className="input-icon-container">
      <img src={props.img} alt="icon" />
      <GenericInput {...props} className={`input-icon-container ${props.className || ''}`}></GenericInput>
    </div>
  );
};

export default InputIcon;
