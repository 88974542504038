import { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import './App.css';
import './Generic.css';
import VerifyEmail from './pages/auth/VerifyEmail';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import NotFound from './pages/notFound/NotFound';
import { useAppSelector } from './store';
import Dashboard from './pages/dashboard/Dashboard';
import FarmDashboard from './pages/farmDashboard/FarmDashboard';
import Categories from './pages/farms/categories/Categories';
import AddCategories from './pages/farms/categories/AddCategories';
import Paddock from './pages/farms/paddocks/Paddocks';
import AddPaddock from './pages/farms/paddocks/AddPaddock';
import SetEndpoints from './components/behavior/setEndpoints/SetEndpoints';
import PendingMovements from './pages/movements/pendingMovements/PendingMovements';
import EditCategories from './pages/farms/categories/EditCategories';
import CreateMovements from './pages/movements/createMovment/CreateMovements';
import Landing from './pages/landing/Landing';
import AboutUs from './pages/aboutUs/AboutUs';
import ContactUs from './pages/contactUs/ContactUs';
import Terms from './pages/terms/Terms';
import Settings from './pages/settings/Settings';
import CompletePageLoader from './components/layout/loaders/completePageLoader/CompletePageLoader';

const queryClient = new QueryClient();
const App = () => {
  const user = useAppSelector((state) => state.user);

  return (
    <ErrorBoundary>
      <CompletePageLoader />
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId="909597890137-52orismo599a8me489ffhdarotmmck3n.apps.googleusercontent.com">
          <BrowserRouter>
            <SetEndpoints />
            <Routes>
              <Route
                path="/"
                element={
                  <Suspense fallback={<>...</>}>
                    <Landing />
                  </Suspense>
                }
              />
              <Route
                path="/about-us"
                element={
                  <Suspense fallback={<>...</>}>
                    <AboutUs />
                  </Suspense>
                }
              />
              <Route
                path="/terms-and-conditions"
                element={
                  <Suspense fallback={<>...</>}>
                    <Terms />
                  </Suspense>
                }
              />
              <Route
                path="/contact-us"
                element={
                  <Suspense fallback={<>...</>}>
                    <ContactUs />
                  </Suspense>
                }
              />
              <Route
                path="/login"
                element={
                  <Suspense fallback={<>...</>}>
                    <Login />
                  </Suspense>
                }
              />
              {!user?.id && (
                <>
                  <Route
                    path="/register"
                    element={
                      <Suspense fallback={<>...</>}>
                        <Register />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/auth/verify-email/:token"
                    element={
                      <Suspense fallback={<>...</>}>
                        <VerifyEmail />
                      </Suspense>
                    }
                  />
                </>
              )}
              <Route
                path="/dashboard"
                element={
                  <Suspense fallback={<>...</>}>
                    <Dashboard />
                  </Suspense>
                }
              />

              {/* farm routes */}
              <Route
                path="farm/:id"
                element={
                  <Suspense fallback={<>...</>}>
                    <FarmDashboard />
                  </Suspense>
                }
              />

              <Route
                path="farm/:id/categories"
                element={
                  <Suspense fallback={<>...</>}>
                    <Categories />
                  </Suspense>
                }
              />
              <Route
                path="farm/:id/categories/add"
                element={
                  <Suspense fallback={<>...</>}>
                    <AddCategories />
                  </Suspense>
                }
              />

              <Route
                path="farm/:id/categories/:categoryId/edit"
                element={
                  <Suspense fallback={<>...</>}>
                    <EditCategories />
                  </Suspense>
                }
              />

              <Route
                path="farm/:id/paddock"
                element={
                  <Suspense fallback={<>...</>}>
                    <Paddock />
                  </Suspense>
                }
              />

              <Route
                path="farm/:id/paddock/add"
                element={
                  <Suspense fallback={<>...</>}>
                    <AddPaddock />
                  </Suspense>
                }
              />

              <Route
                path="farm/:id/movements/pending"
                element={
                  <Suspense fallback={<>...</>}>
                    <PendingMovements />
                  </Suspense>
                }
              />
              <Route
                path="farm/:id/movements/create"
                element={
                  <Suspense fallback={<>...</>}>
                    <CreateMovements />
                  </Suspense>
                }
              />
              <Route
                path="farm/:id/settings"
                element={
                  <Suspense fallback={<>...</>}>
                    <Settings />
                  </Suspense>
                }
              />

              {/* end of farm dash routes */}
              <Route
                path="*"
                element={
                  <Suspense fallback={<>...</>}>
                    <NotFound redirect={user?.id ? 'dashboard' : 'login'} />
                  </Suspense>
                }
              />
            </Routes>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
