import { useEffect, useState } from 'react';
import Card from '../../../components/containers/card/Card';
import VerticalContainer from '../../../components/containers/verticalContainer/VerticalContainer';
import FarmLayout from '../../../components/farmLayout/FarmLayout';
import Table from '../../../components/table/EditableTable';
import { useAppSelector } from '../../../store';
import './UsersSettings.css';
import HorizontalContainer from '../../../components/horizontalContainer/HorizontalContainer';
import Button from '../../../components/inputs/buttons/Button';
import RedButton from '../../../components/inputs/buttons/Redbutton';
import { FORM_INPUT_TYPE, ITableColumn, IUser } from '../../../types';
import AddUser from './AddUser';
import { userService } from '../../../service';
import { useParams } from 'react-router-dom';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import ErrorMessage from '../../../components/inputs/errorMessage/ErrorMessage';
import { axiosHelper } from '../../../utils';

const UsersSettings = () => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<IUser[]>([]);
  const [selected, setSelected] = useState<IUser>();
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const columns = [
    { displayName: 'Nombre', key: 'name', type: FORM_INPUT_TYPE.TEXT },
    {
      displayName: 'Email',
      key: 'email',
      type: FORM_INPUT_TYPE.TEXT,
    },
    {
      displayName: 'Rol',
      key: 'role',
      type: FORM_INPUT_TYPE.TEXT,
    },
  ];
  const farm = useAppSelector((s) => s.farm);

  const getUsers = async () => {
    const res = await userService.getAll({ farmId: id });
    const transformedUser = res.data.map((user: IUser) => {
      return {
        ...user,
        role: user.farmUserRoles.find((rol) => rol.farmId == id)?.role?.name || 'administrador',
      };
    });

    setData(transformedUser);
  };

  const handleDelete = async () => {
    if (!selected) return;
    try {
      await userService.deleteUserFromFarm(selected.id);
      setSelected(undefined);
    } catch (e: unknown) {
      setErrorMessage(axiosHelper.getErrorMessage(e));
    } finally {
      getUsers();
    }
  };
  const handleSelectDelete = async (id: string | number) => {
    const user = data.find((user) => user.id == id);
    setErrorMessage('');
    user && setSelected(user);
  };

  useEffect(() => {
    getUsers();
  }, []);
  return (
    <>
      <AddUser getUsers={getUsers} setShowModal={setShowModal} showModal={showModal} />
      <VerticalContainer className="user-setting-container">
        <Card type="wide">
          <HorizontalContainer className="titles">
            <HorizontalContainer className="">
              <h2>Gestionar Usuarios</h2>
            </HorizontalContainer>
            <HorizontalContainer className="user-settings-buttons-container">
              <Button
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Agregar Usuario
              </Button>
            </HorizontalContainer>
          </HorizontalContainer>

          <Table
            data={data}
            columns={columns as ITableColumn<IUser>[]}
            setData={setData}
            handleDelete={handleSelectDelete}
            isDeleteAlwaysOn={true}
          ></Table>
        </Card>
      </VerticalContainer>
      <ConfirmModal
        text={`Estas seguro que deseas eliminar a ${selected?.name}`}
        title={`Eliminar a ${selected?.name} de ${farm?.name}`}
        showModal={!!selected}
      >
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <HorizontalContainer>
          <Button onClick={handleDelete}>Confirmar</Button>
          <RedButton onClick={() => setSelected(undefined)}>Cancelar</RedButton>
        </HorizontalContainer>
      </ConfirmModal>
    </>
  );
};

export default UsersSettings;
