import { axios } from '../handlers';
import { IPaddock } from './../types/entities.types';
import BaseService from './base.service';
import { ENDPOINTS } from './endpoints';

export class PaddockService extends BaseService<IPaddock> {
  constructor() {
    super(ENDPOINTS.PADDOCKS);
  }

  updatePosition = (id: string | number, position: number) =>
    axios.put(`${this.endpoint()}/${id}/position`, { position }, { withCredentials: true });
}

export const paddockService = new PaddockService();
