import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CardLink from '../../../components/containers/card/CardLink';
import ListWithChildrenLine from '../../../components/generic/listhWithChildren/ListWithChildrenLine';
import Separator from '../../../components/generic/separator/Separator';
import { paddockService } from '../../../service';
import { IPaddock, listProp } from '../../../types';

const FarmPaddockCard = () => {
  const { id } = useParams();
  const [data, setData] = useState<listProp[]>([]);

  const handleGetPaddocks = async () => {
    if (!id) return;
    const { data } = await paddockService.getAll({ farmId: id });
    const result = data.map((pad) => ({
      name: pad.name,
      ...handleChilds(pad),
    }));
    setData(result);
  };

  const handleChilds = (pad: IPaddock) => {
    return pad.animals.reduce(
      (acc, curr) => {
        acc.value += curr.quantity;
        acc.children.push({ name: curr.subCategory?.name || '', value: curr.quantity });
        return acc;
      },
      { value: 0, children: [] } as {
        value: number;
        children: { name: string; value: number }[];
      },
    );
  };
  useEffect(() => {
    handleGetPaddocks();
  }, [id]);
  return (
    <CardLink to={'paddock'} key={'movements'} className="wide-card">
      <div className="title-container">
        <h2>{'Potreros'}</h2>
      </div>
      <Separator />
      {data?.map((movement, i) => (
        <ListWithChildrenLine {...movement} />
      ))}
    </CardLink>
  );
};

export default FarmPaddockCard;
