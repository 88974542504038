import React, { useEffect } from 'react';
import { IFormInput } from '../../../types';
import ErrorMessage from './../errorMessage/ErrorMessage';

const SelectInput: React.FC<IFormInput<HTMLSelectElement>> = ({
  id,
  label,
  type = 'text',
  defaultValue,
  onChange,
  ref,
  errorMessage,
  placeholder,
  isNotValid,
  onTouched,
  touched,
  className = '',
  options = [],
  disabled,
}) => {
  // const presentRef = ref || React.createRef<HTMLSelectElement>()

  return (
    <div className={`generic-input-container  select-input-container ${className}`} key={id}>
      {label && <label htmlFor={id}>{label}</label>}
      <select
        id={id}
        name={id}
        onChange={onChange}
        // ref={presentRef}
        defaultValue={defaultValue || '-1'}
        placeholder={placeholder}
        onBlur={onTouched}
      >
        <option value={'-1'} disabled={!defaultValue || disabled}>
          {'Seleccione una opcion'}
        </option>
        {options.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
      {isNotValid && touched && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

export default SelectInput;
