import { BUTTON_TYPE, IFormButton } from '../../../../types';
import Button from '../../../inputs/buttons/Button';

const HandleButton: React.FC<IFormButton> = (props) => {
  return props.type === BUTTON_TYPE.SUBMIT ? (
    <Button key={props.text} {...props} onClick={undefined} disabled={props.disabled} />
  ) : (
    <Button key={props.text} {...props} disabled={false} />
  );
};

export default HandleButton;
