import { useEffect, useState } from 'react';
import CardLink from '../../../components/containers/card/CardLink';
import Separator from '../../../components/generic/separator/Separator';
import { useAppSelector } from '../../../store';
import { movementsService } from '../../../service/movements.service';

const FarmMovement = () => {
  const [movements, setMovements] = useState<{ message: string }[]>([]);
  const farm = useAppSelector((s) => s.farm);
  const handleGetMovements = async () => {
    if (!farm?.id) return;
    const data = await movementsService.getAll(farm.id);
    setMovements(data.data);
  };

  useEffect(() => {
    handleGetMovements();
  }, []);

  return (
    <CardLink to={'movements/pending'} key={'movements'} className="wide-card">
      <div className="title-container">
        <h2>{'Movimientos Recientes'}</h2>
      </div>
      <Separator />
      <ul>
        {movements?.map((movement, i) => (
          <li key={i}>{movement.message}</li>
        ))}
      </ul>
    </CardLink>
  );
};

export default FarmMovement;
