import VerticalContainer from '../../components/containers/verticalContainer/VerticalContainer';
import Header from '../../components/header/Header';
import './Terms.css';

const Terms = () => {
  return (
    <VerticalContainer className="landing-container terms-container">
      <Header />
      <h2>Terminos y Condiciones</h2>
      <p>
        Bienvenido a Ranchit. Los siguientes términos y condiciones ("Términos") se aplican a su acceso y uso
        de la aplicación móvil Ranchit ("Aplicación"). Al utilizar la Aplicación, acepta estos Términos. Si no
        acepta estos Términos, no utilice la Aplicación.
      </p>
      <ol>
        <li>
          Descripción de la Aplicación La Aplicación de Ranchit es una plataforma de gestión de stock
          ganadero. La aplicación permite a los usuarios registrar y hacer seguimiento del ganado, gestionar
          el inventario y recibir notificaciones relacionadas con la gestión del ganado. La Aplicación está
          destinada a ser utilizada por criadores de ganado y otros profesionales de la industria ganadera.
        </li>
        <li>
          Propiedad intelectual Los derechos de propiedad intelectual, incluyendo derechos de autor, marcas
          comerciales y patentes, sobre la Aplicación y su contenido son propiedad exclusiva de Ranchit o sus
          licenciantes. No se le otorga ningún derecho de propiedad intelectual sobre la Aplicación ni su
          contenido, excepto lo que se le conceda expresamente en estos Términos.
        </li>
        <li>
          Registro de cuenta Para utilizar la Aplicación, debe registrarse y crear una cuenta de usuario. Es
          responsable de mantener la confidencialidad de su cuenta y contraseña, y acepta no compartir su
          cuenta con terceros. Es responsable de toda actividad que se realice en su cuenta.
        </li>
        <li>
          Uso de la Aplicación Usted acepta utilizar la Aplicación de acuerdo con estos Términos y todas las
          leyes y regulaciones aplicables. No puede utilizar la Aplicación de manera que interfiera o
          interrumpa el servicio para otros usuarios. Usted acepta no utilizar la Aplicación para cualquier
          propósito ilegal o no autorizado..
        </li>
        <li>
          Responsabilidad del usuario Usted acepta que es responsable de cualquier actividad que se realice en
          su cuenta y que cualquier uso de la Aplicación por parte de usted se realiza bajo su propio riesgo.
          Usted acepta indemnizar a Ranchit y sus afiliados, empleados, agentes y representantes de cualquier
          reclamo o demanda, incluyendo honorarios razonables de abogados, hechos por cualquier tercero debido
          a o como resultado de su uso de la Aplicación.
        </li>
        <li>
          Cancelación de la cuenta Ranchit se reserva el derecho de cancelar su cuenta en cualquier momento y
          por cualquier razón. Si se cancela su cuenta, se le notificará por correo electrónico y su acceso a
          la Aplicación se interrumpirá inmediatamente.
        </li>
        <li>
          Modificaciones de los Términos Ranchit se reserva el derecho de modificar estos Términos en
          cualquier momento. Si se realizan cambios importantes, se le notificará por correo electrónico y se
          le pedirá que acepte los nuevos términos antes de continuar utilizando la Aplicación.
        </li>
        <li>
          Limitación de responsabilidad La Aplicación se proporciona "tal cual" sin garantías de ningún tipo,
          ya sean expresas o implícitas. Ranchit no garantiza que la Aplicación sea precisa, completa,
          confiable, actualizada, segura o libre de errores. En ningún caso Ranchit será responsable ante
          usted o cualquier tercero por cualquier daño indirecto, incidental, especial o consecuencial,
          incluyendo pérdida de beneficios, ingresos, datos o uso, relacionados con su uso o incapacidad
        </li>
      </ol>
    </VerticalContainer>
  );
};

export default Terms;
