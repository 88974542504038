import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { categoryService } from '../../../service';
import { generalActions, useAppDispatch } from '../../../store';

const SetEndpoints = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const getFarmId = () => location.pathname.match(/\/farm\/(\d+)\//)?.[1];
  useEffect(() => {
    const farmId = getFarmId();
    dispatch(generalActions.setPartial({ farmId }));
  }, [location]);

  return <></>;
};

export default SetEndpoints;
