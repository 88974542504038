import { farmsIcon, statsIcons, movementIcon, cardIcon, settingsIcon } from '../../../images/icons';
import LinkIcon from '../../inputs/linkIcon/LinkIcon';
import Sidebar from '../sidebar/Sidebar';

const MainSideBar = () => {
  return (
    <Sidebar>
      <h4>Dashboard</h4>
      <LinkIcon
        to={'/dashboard'}
        text="Establecimientos"
        icon={farmsIcon}
        activeclassName="active"
        className="className"
      />
      <LinkIcon
        to={'/'}
        text="Estadísticas"
        icon={statsIcons}
        activeclassName="active"
        className="disabledFirstRealse"
      />
      <LinkIcon
        to={'/'}
        text="Movimientos"
        icon={movementIcon}
        activeclassName="active"
        className="disabledFirstRealse"
      />
      <LinkIcon
        to={'/'}
        text="Suscripción"
        icon={cardIcon}
        activeclassName="active"
        className="disabledFirstRealse"
      />
      <LinkIcon
        to={'/'}
        text="ajustes"
        icon={settingsIcon}
        activeclassName="active"
        className="disabledFirstRealse"
      />
    </Sidebar>
  );
};

export default MainSideBar;
