import VerticalContainer from '../../../components/containers/verticalContainer/VerticalContainer';
import EditableTable from '../../../components/table/EditableTable';
import { observationService } from '../../../service';
import { FORM_INPUT_TYPE, IObservations } from '../../../types';
import './PaddockObservations.css';

interface IProps {
  observations?: IObservations[];
  setObservations: React.Dispatch<React.SetStateAction<IObservations[]>>;
  isEditing: boolean;
  getPaddocks: () => Promise<void>;
}
export const PaddockObservations = ({ observations, isEditing, getPaddocks, setObservations }: IProps) => {
  const handleDelete = async (id: string | number) => {
    if (!observations) return;
    await observationService.delete(id, observations[0].paddockId);
    await getPaddocks();
  };

  return (
    <>
      {observations?.length || isEditing ? (
        <VerticalContainer className="paddock-observations">
          <h3>Observaciones</h3>
          <EditableTable<IObservations>
            data={observations || []}
            isEditing={isEditing}
            isDeleteAlwaysOn={true}
            columns={[{ key: 'value', displayName: '', type: FORM_INPUT_TYPE.TEXT }]}
            setData={setObservations}
            handleDelete={handleDelete}
            isHiddenHeaders={true}
            addText="Agregar Observacion"
            className="paddock-observations-table"
          />
        </VerticalContainer>
      ) : (
        <></>
      )}
    </>
  );
};
