import { ISubCategory } from './../types/entities.types';
import BaseService from './base.service';
import { ENDPOINTS } from './endpoints';

export class SubCategoryService extends BaseService<ISubCategory> {
  constructor() {
    super(ENDPOINTS.SUB_CATEGORIES);
  }
}

export const subCategoryService = new SubCategoryService();
