import Logo from '../../generic/Logo';
import LogoutButton from '../../inputs/buttons/logoutButton/LogoutButton';
import './Sidebar.css';

export interface Props {
  children?: React.ReactNode;
  isNotShowingLogoutButton?: boolean;
  logoutButtonclass?: string;
}
const Sidebar = ({ children, isNotShowingLogoutButton, logoutButtonclass = 'link-icon' }: Props) => {
  return (
    <div className="sidebar-container">
      <Logo className="sidebar" />
      <div className="links-container">{children}</div>
      <LogoutButton className={logoutButtonclass} />
    </div>
  );
};

export default Sidebar;
