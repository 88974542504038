import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../components/containers/card/Card';
import CardSplited from '../../../components/containers/cardSplited/CardSplited';
import CenterContainer from '../../../components/containers/centeredContainer/CenterContainer';
import FarmLayout from '../../../components/farmLayout/FarmLayout';
import StepForm from '../../../components/generic/stepForm/StepForm';
import TitleSubTitle from '../../../components/generic/TitleSubTitle';
import { ICategories } from '../../../types';
import { addCategoriesForm } from './AddCategories.form';

const AddCategories = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [stepIndex, setStepIndex] = useState(0);
  const form = addCategoriesForm(navigate, id || -1);

  return (
    <FarmLayout>
      <CenterContainer className="add-category-container">
        <Card type="wide" className="center">
          <TitleSubTitle
            text="Complete los siguientes pasos para crear la categoria"
            title="Agregar Categoria"
            isShowSeparator={true}
          />
          <StepForm<ICategories> steps={form} stepIndex={stepIndex} setStepIndex={setStepIndex} />
        </Card>
      </CenterContainer>
    </FarmLayout>
  );
};

export default AddCategories;
