import { ITableColumn } from '../../types';
import { functionHelper } from '../../utils';
import TableCellView from './TableCellView';
import TableInput from './TableInput';

interface IProps<T> {
  column: ITableColumn<T>;
  row: T;
  isEditing: boolean;
  index: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const handleCell = <T extends { id: string | number }>({
  column,
  row,
  isEditing,
  index,
  onChange,
}: IProps<T>) => {
  const id = `${column.key as string}_${row.id}`;
  const value = functionHelper.getValueRecusive(
    row,
    column.keyPath || (column.key as string),
    column.defaultValue,
  );
  return (
    <td>
      {isEditing ? (
        <TableInput
          input={{
            type: column.type,
            id,
            defaultValue: value,
            // disabled: !row[column.key] && column.defaultValue ? true : false,
            onChange: onChange,
            options: column.options,
          }}
        />
      ) : (
        <TableCellView id={id} column={column} row={row} onChange={onChange} />
      )}
    </td>
  );
};

export default handleCell;
