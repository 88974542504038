import Header from '../layout/header/Header';
import Sidebar from '../layout/sidebar/Sidebar';
import FarmSidebar from './FarmSidebar';
import './FarmLayout.css';
import VerticalContainer from '../containers/verticalContainer/VerticalContainer';
export interface Props {
  children?: React.ReactNode;
  className?: string;
  headerChildren?: React.ReactNode;
  headerClassName?: string;
  title?: string;
  description?: string;
}

const FarmLayout = ({
  children,
  className = '',
  headerChildren,
  title = '',
  description = '',
  headerClassName = '',
}: Props) => {
  return (
    <>
      <FarmSidebar />
      <Header className={headerClassName}>
        {headerChildren || (
          <VerticalContainer className="farm-layout-header">
            <h2>{title}</h2>
            <p>{description}</p>
          </VerticalContainer>
        )}
      </Header>
      <div className={`main-content ${className}`}>{children}</div>
    </>
  );
};

export default FarmLayout;
