import qs from 'qs';
import { axios } from '../handlers';
import { DeepPartial, PaginatedResponse, Params } from '../types';

export default class BaseService<T extends { id: string | number }> {
  public endpoint: () => string;
  constructor(endpoint: () => string) {
    this.endpoint = endpoint;
  }

  public getAll = async (params: Params = {}) => {
    const elem = await axios.get(`${this.endpoint()}?${qs.stringify(params)}`);
    return elem.data as PaginatedResponse<T[]>;
  };
  public get = async (id: string | number): Promise<{ data: T; message: string }> =>
    (await axios.get(`${this.endpoint()}/${id}`)).data;

  public create = async (element: T) => {
    return await axios.post(this.endpoint(), element, { withCredentials: true });
  };

  public update = async (element: DeepPartial<T>) => {
    return await axios.put(`${this.endpoint()}/${element.id}`, element);
  };
  public updateMultiple = async (element: DeepPartial<T>[]) => {
    return await axios.put(this.endpoint(), element);
  };
  public delete = async (id: string | number) => {
    return await axios.delete(`${this.endpoint()}/${id}`);
  };
}
