import { NavigateFunction } from 'react-router-dom';
import { BUTTON_TYPE, FORM_INPUT_TYPE } from '../../types';
import { EMAIL_REGEXP } from '../../types/regexp';

export const loginFields = [
  {
    id: 'email',
    label: 'Email',
    placeholder: 'Enter your email',
    required: true,
    validate: (value: string) => EMAIL_REGEXP.test(value),
    errorMessage: 'el email es requerido',
    type: FORM_INPUT_TYPE.TEXT,
  },

  {
    id: 'password',
    label: 'Contraseña',
    placeholder: 'Enter your password',
    required: true,
    validate: (value: string) => !!value.length,
    errorMessage: 'la contraseña es requerida',
    type: FORM_INPUT_TYPE.PASSWORD,
  },
];

export const loginButtons = (navigate: NavigateFunction) => [
  {
    type: BUTTON_TYPE.SUBMIT,
    children: 'Ingresar',
  },
  {
    type: BUTTON_TYPE.BUTTON,
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => navigate('/register'),
    children: 'Registrarse',
    className: 'secondary',
  },
];
