import dayjs from 'dayjs';
import { FORM_INPUT_TYPE, ITableColumn } from '../../types';
import { functionHelper } from '../../utils';
import ToggleSwitch from '../inputs/toggleSwitch/ToggleSwitch';

interface IProps<T> {
  column: ITableColumn<T>;
  row: T;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
}

const TableCellView = <T extends { id: string | number }>({ column, row, onChange, id }: IProps<T>) => {
  const value = functionHelper.getValueRecusive(
    row,
    column.keyPath || (column.key as string),
    column.defaultValue,
  );
  return (
    <>
      {column.type === FORM_INPUT_TYPE.SELECT &&
        `${column.options?.find((x) => x.value == value)?.label || value}`}
      {column.type === FORM_INPUT_TYPE.DATE &&
        `${value ? dayjs(value as string, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}`}
      {column.type === FORM_INPUT_TYPE.BOOLEAN && (
        <ToggleSwitch value={Boolean(value)} handleClick={onChange} id={id} />
      )}
      {(column.type === FORM_INPUT_TYPE.TEXT || column.type === FORM_INPUT_TYPE.NUMBER) && `${value || ''}`}
    </>
  );
};

export default TableCellView;
