import { listProp } from '../../../types';
import VerticalContainer from '../../containers/verticalContainer/VerticalContainer';
import HorizontalContainer from '../../horizontalContainer/HorizontalContainer';
import './ListhWithChildren.css';
const ListWithChildrenLine = ({ name, value = 0, children = [] }: listProp) => {
  return (
    <div className="list-with-children-container">
      <HorizontalContainer>
        <p>{name}</p>
        <p>{value}</p>
      </HorizontalContainer>
      <VerticalContainer className="list-with-children">
        {children.map((item, i) => (
          <ListWithChildrenLine {...item} />
        ))}
      </VerticalContainer>
    </div>
  );
};

export default ListWithChildrenLine;
