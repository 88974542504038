import { ISaid } from './../types/entities.types';
import BaseService from './base.service';
import { ENDPOINTS } from './endpoints';

export class SaidService extends BaseService<ISaid> {
  constructor() {
    super(ENDPOINTS.SAIDS);
  }
}

export const saidService = new SaidService();
