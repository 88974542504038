import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CardList from '../../../components/containers/card/CardList';
import FarmLayout from '../../../components/farmLayout/FarmLayout';
import LinkButton from '../../../components/inputs/buttons/LinkButton';
import { animalColumnService, paddockService, saidService } from '../../../service';
import { subCategoryService } from '../../../service/subCategory.service';
import { useAppSelector } from '../../../store';
import { ANIMAL_TYPE, IAnimalColumn, IPaddock, ISaid, ISubCategory } from '../../../types';
import './PendingMovements.css';
import PendingMovementsCard from './PendingMovementsCard';

const PendingMovements = () => {
  const { id } = useParams();
  const farm = useAppSelector((state) => state.farm);
  const [categories, setCategories] = useState<ISubCategory[] | null>(null);
  const [activeColumns, setActiveColumns] = useState<IAnimalColumn[]>([]);
  const [paddocks, setPaddocks] = useState<IPaddock[] | null>(null);
  const [saids, setSaid] = useState<ISaid[] | null>(null);
  const getCategories = async () => {
    const { data } = await subCategoryService.getAll({ farmId: id });
    setCategories(data);
  };

  const getPaddocks = async () => {
    const { data } = await paddockService.getAll({ farmId: id });
    setPaddocks(data);
  };

  const getSaids = async () => {
    const { data } = await saidService.getAll({ animalType: ANIMAL_TYPE.PENDING });
    setSaid(data);
  };

  const getAnimalColumns = async () => {
    const { data } = await animalColumnService.getAll();
    setActiveColumns(data);
  };

  useEffect(() => {
    getCategories();
    getSaids();
    getPaddocks();
    getAnimalColumns();
  }, []);
  return (
    <FarmLayout
      className="dashboard paddocks pending-movements"
      title={farm?.name || ''}
      description="Categorias"
      headerChildren={
        <>
          <h2 className="white-font"> Movimientos Pendientes</h2>
          <LinkButton className="create-movement-link" to={`/farm/${id}/movements/create`}>
            {' '}
            Crear Movimiento
          </LinkButton>
        </>
      }
    >
      {categories && (
        <CardList className={'farm-dashboard-list'}>
          {saids?.map((said) => (
            <PendingMovementsCard
              said={said}
              categoriesOptions={categories?.map((c) => ({ value: c.id, label: c.name }))}
              paddockOptions={paddocks?.map((c) => ({ value: c.id, label: c.name }))}
              getSaids={getSaids}
              activeColumns={activeColumns}
            />
          ))}
        </CardList>
      )}
    </FarmLayout>
  );
};

export default PendingMovements;
