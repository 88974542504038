import React from 'react';
import './CheckBoxInput.css';

export interface IProps {
  label?: string;
  type?: string;
  id: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  checked?: boolean;
}
const CheckBoxInput: React.FC<IProps> = ({ id, label, type = 'checkbox', onChange, onClick, checked }) => (
  <div className="checkbox-input-container">
    <input type={type} id={id} name={id} onChange={onChange} onClick={onClick} checked={checked} />
    {label && <label htmlFor={id}>{label}</label>}
  </div>
);

export default CheckBoxInput;
