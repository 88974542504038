import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import './Mainbody.css';
import MainSideBar from './MainSideBar';
export interface Props {
  children?: React.ReactNode;
  className?: string;
  headerChildren?: React.ReactNode;
}

const Mainbody = ({ children, className = '', headerChildren }: Props) => {
  return (
    <>
      <MainSideBar />
      <Header>{headerChildren}</Header>
      <div className={`main-content ${className}`}>{children}</div>
    </>
  );
};

export default Mainbody;
