import { LogoNormal } from '../../images';
import './Logo.css';

export interface Props {
  className?: string;
}
const Logo = ({ className = '' }: Props) => {
  return (
    <div className={`logo-container ${className}`}>
      <img src={LogoNormal} alt="logo" />
      <h1 className="primary">
        Ranch<span>It</span>
      </h1>
    </div>
  );
};

export default Logo;
