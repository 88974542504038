import { useState } from 'react';
import HorizontalContainer from '../../../components/horizontalContainer/HorizontalContainer';
import GenericInput from '../../../components/inputs/GenericInput';
import Button from '../../../components/inputs/buttons/Button';
import RedButton from '../../../components/inputs/buttons/Redbutton';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import { EMAIL_REGEXP } from '../../../types/regexp';
import { userService } from '../../../service';
import { AxiosError } from 'axios';

interface IProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  getUsers: () => void;
}
const AddUser = ({ showModal, setShowModal, getUsers }: IProps) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('El email no es correcto');
  const [touched, setTouched] = useState(false);
  const [isNotValid, setIsNotValid] = useState(true);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (EMAIL_REGEXP.test(e.target.value)) setIsNotValid(false);
    else {
      setIsNotValid(true);
      setErrorMessage('El email no es correcto');
    }
  };

  const handleAddUser = async () => {
    if (isNotValid) return;

    try {
      await userService.addUserToFarm(email);
      setShowModal(false);
    } catch (err: unknown | AxiosError<{ message: string }>) {
      setIsNotValid(true);
      if (err instanceof AxiosError<{ message: string }>)
        setErrorMessage(
          err?.response?.status != 500 && err?.response?.data?.message
            ? err?.response?.data?.message
            : 'error agregando el usuario',
        );
    } finally {
      getUsers();
    }
  };

  return (
    <ConfirmModal text="Agregue el usuario con su email" title={'Agregar Usuario'} showModal={showModal}>
      <GenericInput
        id={'email'}
        onChange={handleChange}
        placeholder="ejemplo@gmail.com"
        errorMessage={errorMessage}
        isNotValid={isNotValid}
        touched={touched}
        onTouched={() => setTouched(true)}
      ></GenericInput>
      <HorizontalContainer className="">
        <Button onClick={handleAddUser} disabled={isNotValid}>
          Agregar Usuario
        </Button>
        <RedButton
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancelar
        </RedButton>
      </HorizontalContainer>
    </ConfirmModal>
  );
};

export default AddUser;
