import HorizontalContainer from '../../../components/horizontalContainer/HorizontalContainer';

interface Props {
  stats?: { name: string; value: string | number }[];
}
const DashboardStats = ({ stats = [] }: Props) => {
  return (
    <>
      <div className="summary-container">
        <h3>Resumen</h3>
        {stats?.map((stat) => (
          <div className="summary" key={stat.name}>
            <HorizontalContainer>
              <p>{stat.name}</p>
              <p>{stat.value}</p>
            </HorizontalContainer>
          </div>
        ))}
      </div>
    </>
  );
};

export default DashboardStats;
