import { store } from '../store';
import generalSlice from '../store/general.slice';
import axiosWrapper from './axios.wrapper';

const axios = axiosWrapper();

axios.interceptors.request.use(
  function (config) {
    store.dispatch(generalSlice.actions.setLoading(true));
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    store.dispatch(generalSlice.actions.setLoading(false));
    if (response.data?.token) {
    }

    return response;
  },
  function (error) {
    store.dispatch(generalSlice.actions.setLoading(false));
    const errorMessage = error.response?.data?.message || 'Error desconocido';
    throw error;
  },
);

export default axios;
