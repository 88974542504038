import { Link, useLocation, useNavigate } from 'react-router-dom';
import HorizontalContainer from '../horizontalContainer/HorizontalContainer';
import Button from '../inputs/buttons/Button';
import Logo from '../generic/Logo';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleStart = () => {
    navigate('/login');
  };

  return (
    <div className="header">
      <HorizontalContainer className="header-container">
        <Logo />
        <div className="header-tabs">
          <Link to="/" className={`${location.pathname.length <= 1 ? 'active' : ''}`}>
            Inicio
          </Link>
          <Link to="/about-us" className={`${location.pathname?.includes('about-us') ? 'active' : ''}`}>
            Sobre nosotros
          </Link>
          <Link to="/contact-us" className={`${location.pathname?.includes('contact-us') ? 'active' : ''}`}>
            Contacto
          </Link>
          <Link
            to="/terms-and-conditions"
            className={`${location.pathname?.includes('terms-and-conditions') ? 'active' : ''}`}
          >
            Términos y condiciones
          </Link>
          <Button onClick={handleStart}>Comenzar</Button>
        </div>
      </HorizontalContainer>
    </div>
  );
};

export default Header;
