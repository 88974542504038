import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../components/containers/card/Card';
import CenterContainer from '../../../components/containers/centeredContainer/CenterContainer';
import FarmLayout from '../../../components/farmLayout/FarmLayout';
import { FormProps } from '../../../components/generic/form/Form';
import StepForm from '../../../components/generic/stepForm/StepForm';
import TitleSubTitle from '../../../components/generic/TitleSubTitle';
import { categoryService } from '../../../service';
import { ICategories } from '../../../types';
import { editCategoriesForm } from './EditCategoriesForm';

const EditCategories = () => {
  const navigate = useNavigate();
  const { id, categoryId } = useParams();
  const [stepIndex, setStepIndex] = useState(0);
  const [category, setCategory] = useState<ICategories>();

  const getCategory = async () => {
    if (!categoryId || !id) return navigate(`/farm/${id}/categories`);
    const category = await categoryService.get(categoryId);
    setCategory(category.data);
  };

  const form = editCategoriesForm(navigate, id || '', category);

  useEffect(() => {
    getCategory();
  }, []);
  return (
    <FarmLayout>
      <CenterContainer className="add-category-container">
        <Card type="wide" className="center">
          <TitleSubTitle text="Edite los siguientes datos" title="Editar Categoria" isShowSeparator={true} />
          {category && form && (
            <StepForm<ICategories> steps={form} stepIndex={stepIndex} setStepIndex={setStepIndex} />
          )}
        </Card>
      </CenterContainer>
    </FarmLayout>
  );
};

export default EditCategories;
