import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Card from '../../../components/containers/card/Card';
import HorizontalContainer from '../../../components/horizontalContainer/HorizontalContainer';
import Button from '../../../components/inputs/buttons/Button';
import RedButton from '../../../components/inputs/buttons/Redbutton';
import EditableTable from '../../../components/table/EditableTable';
import { paddockService } from '../../../service';
import { IAnimal, IObservations, IPaddock, ITableColumn } from '../../../types';
import { MOVEMENTS_TYPE, showDestinyMovements } from '../../../types/movements.types';
import { PaddockObservations } from '../../farms/paddocks/PaddockObservations';

interface IProps {
  paddock?: IPaddock;
  columns: ITableColumn<IAnimal>[];
  getPaddocks: () => Promise<void>;
  handleBack: () => void;
  operationType?: MOVEMENTS_TYPE;
}
const MovementEntry = ({ paddock, operationType, columns, getPaddocks, handleBack }: IProps) => {
  const [newAnimals, setNewAnimals] = useState<IAnimal[]>([]);
  const [observations, setObservations] = useState<IObservations[]>(paddock?.observations || []);
  const handleOnChange = (value: IAnimal) => {
    const prevState = newAnimals.find((d) => d.id === value.id);

    if (prevState?.startData !== value.startData)
      value.permanenceDate = dayjs(value.startData, 'YYYY-MM-DD').add(90, 'd').format('YYYY-MM-DD');
    else if (prevState?.permanenceDate !== value.permanenceDate)
      value.startData = dayjs(value.permanenceDate, 'YYYY-MM-DD').add(-90, 'd').format('YYYY-MM-DD');

    return value;
  };

  const handleSumbit = async () => {
    if (!paddock) return;
    const animals = newAnimals.map((animal) => ({ ...animal, id: undefined, paddockId: paddock.id }));
    const observationsToSend = observations.map((obs) => ({
      ...obs,
      id: Number(obs.id) >= 0 ? obs.id : undefined,
      paddockId: paddock.id,
    }));

    await paddockService.update({ ...paddock, animals, observations: observationsToSend });
    handleBack();
  };

  useEffect(() => {
    setObservations(paddock?.observations || []);
  }, [paddock]);

  return (
    <>
      {paddock && operationType && showDestinyMovements.includes(operationType) && (
        <Card type="ultra-wide" className="paddock-card">
          <HorizontalContainer>
            <HorizontalContainer className="paddocks-card-title">
              <h2>{paddock?.name}</h2>
            </HorizontalContainer>
          </HorizontalContainer>
          <EditableTable<IAnimal>
            data={paddock.animals as unknown as IAnimal[]}
            isEditing={false}
            columns={columns}
            setData={() => {}}
            addText="Agregar Animal"
          />
          <EditableTable<IAnimal>
            data={newAnimals}
            onChange={handleOnChange}
            isEditing={true}
            isHiddenHeaders={true}
            columns={columns}
            setData={setNewAnimals}
            addText="Agregar Animal"
          />
          <PaddockObservations
            observations={observations}
            setObservations={setObservations}
            isEditing={true}
            getPaddocks={getPaddocks}
          />
          <HorizontalContainer className="button-container">
            <RedButton className="" onClick={handleBack}>
              Cancelar
            </RedButton>
            <Button className="secondary" onClick={handleSumbit}>
              Confirmar
            </Button>
          </HorizontalContainer>
        </Card>
      )}
    </>
  );
};

export default MovementEntry;
