import { useEffect, useState } from 'react';
import { IFormInput } from '../types';

export const useForm = (initialState: IFormInput[]) => {
  const initialvalues = initialState.reduce((acc, input) => {
    acc[input.id] = {
      ...input,
      value: input.defaultValue,
      isNotValid: false,
    };
    return acc;
  }, {} as { [key: string]: IFormInput & { value: string | undefined } });
  const [state, setState] = useState(initialvalues);
  const [disabled, setDisabled] = useState(true);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setState((prevState) => {
      const pervStateVal = prevState[id];
      return {
        ...prevState,
        [id]: {
          ...pervStateVal,
          value,
          isNotValid: pervStateVal.validate ? !pervStateVal.validate(value) : false,
        },
      };
    });
  };

  const updateForm = (inputs: IFormInput[]) => {
    const newInputs = inputs.reduce((acc, input) => {
      acc[input.id] = {
        ...input,
        value: input.defaultValue,
        isNotValid: false,
      };
      return acc;
    }, {} as { [key: string]: IFormInput & { value: string | undefined } });
    setState((prev) => ({ ...newInputs, ...prev }));
  };

  const validate = () => {
    let isValid = true;
    Object.values(state).forEach((input) => {
      if (input.required && !input.value) isValid = false;
      if (input.validate && !input.validate(input.value || '')) isValid = false;
    });
    setDisabled(!isValid);
    return isValid;
  };

  const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validate()) return;
    return Object.values(state).reduce((acc, input) => {
      return { ...acc, [input.id]: input.value };
    }, {} as { [key: string]: string | undefined });
  };

  const onTouched = (e: React.FocusEvent<HTMLInputElement>) => {
    const { id } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        touched: true,
      },
    }));
  };

  useEffect(() => {
    validate();
  });
  return { state, disabled, onChange, onSubmitForm, onTouched, updateForm };
};
