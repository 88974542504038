import { useState } from 'react';
import { ITableColumn } from '../../../types';
import './ToggleSwitch.css';

interface IProps {
  value: boolean;
  handleClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
}

const ToggleSwitch = ({ value, handleClick, id }: IProps) => {
  const [isChecked, setIsChecked] = useState(value);
  const handleIsChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = `${!isChecked}`;
    setIsChecked((isCheck) => !isCheck);
    if (handleClick) handleClick(e);
  };
  return (
    <label className="switch">
      <input type="checkbox" onChange={handleIsChecked} id={id} checked={isChecked} />
      <span className="slider round"></span>
    </label>
  );
};

export default ToggleSwitch;
