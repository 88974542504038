import { Link } from 'react-router-dom';
import './Card.css';

export interface Props {
  children?: React.ReactNode;
  className?: string;
  to?: string;
  type?: 'wide' | 'normal' | 'ultra-wide';
}
const CardLink = ({ children, className = '', to = '', type }: Props) => {
  return (
    <Link to={to} className={`main-card ${type ? type : ''} ${className} `}>
      <div className="content">{children}</div>
    </Link>
  );
};

export default CardLink;
