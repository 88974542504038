import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  redirect?: string;
}
const NotFound = ({ redirect = 'login' }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(redirect, { replace: true });
  }, []);

  return (
    <>
      <h1>404 pagina no encontrada</h1>
      <p>Redireccionado...</p>
    </>
  );
};

export default NotFound;
