import VerticalContainer from '../../components/containers/verticalContainer/VerticalContainer';
import Header from '../../components/header/Header';
import HorizontalContainer from '../../components/horizontalContainer/HorizontalContainer';
import { FirstAbout, FourthAbout, SecondAbout, ThirdAbout } from '../../images';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <VerticalContainer className="landing-container about-us-container">
      <Header />
      <HorizontalContainer className="half-spliter first">
        <VerticalContainer className="paragraph">
          <p>
            En Ranchit, estamos dedicados a proporcionar una solución completa para la gestión del stock
            ganadero. Somos una empresa de tecnología que tiene como objetivo mejorar la eficiencia y la
            productividad de los criadores de ganado y otros profesionales de la industria ganadera.
          </p>
        </VerticalContainer>
        <VerticalContainer>
          <img src={FirstAbout} alt="phone with app logo" />
        </VerticalContainer>
      </HorizontalContainer>
      <HorizontalContainer className="half-spliter">
        <VerticalContainer>
          <img src={SecondAbout} alt="phone with app logo" />
        </VerticalContainer>
        <VerticalContainer className="paragraph">
          <p>
            Nuestro equipo está formado por expertos en tecnología y profesionales con experiencia en la
            industria ganadera. Juntos, hemos creado una aplicación móvil fácil de usar que permite a los
            usuarios gestionar y hacer seguimiento del ganado, así como recibir notificaciones relacionadas
            con la gestión del ganado.
          </p>
        </VerticalContainer>
      </HorizontalContainer>
      <HorizontalContainer className="half-spliter">
        <VerticalContainer className="paragraph">
          <p>
            En Ranchit, nos esforzamos por proporcionar a nuestros usuarios la mejor experiencia posible.
            Estamos comprometidos con la excelencia en todo lo que hacemos, desde el diseño de la aplicación
            hasta el soporte al cliente. Valoramos la retroalimentación de nuestros usuarios y estamos
            constantemente trabajando para mejorar la funcionalidad y el rendimiento de la aplicación.{' '}
          </p>
        </VerticalContainer>
        <VerticalContainer>
          <img src={ThirdAbout} alt="phone with app logo" />
        </VerticalContainer>
      </HorizontalContainer>
      <HorizontalContainer className="half-spliter">
        <VerticalContainer>
          <img src={FourthAbout} alt="phone with app logo" />
        </VerticalContainer>
        <VerticalContainer className="paragraph">
          <p>
            Nuestra visión es convertirnos en el líder de la industria en la gestión del stock ganadero, y
            estamos trabajando incansablemente para alcanzar ese objetivo. En Ranchit, creemos que la
            tecnología puede ser una fuerza impulsora en la mejora de la eficiencia y la sostenibilidad en la
            industria ganadera.
          </p>
        </VerticalContainer>
      </HorizontalContainer>
      <h3>
        Gracias por elegir Ranchit. Estamos emocionados de ayudarle a gestionar su stock ganadero de manera
        más eficiente y efectiva.
      </h3>
    </VerticalContainer>
  );
};

export default AboutUs;
