import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import './DraggableList.css';
import { useAppDispatch, generalActions } from '../../../store';

export interface IDraggableElement {
  components?: React.ReactNode;
  id: string;
}
[];

interface Props {
  elements: IDraggableElement[];
  updatePosition: (id: number | string, position: number) => Promise<void>;
}
const DraggableList = ({ elements, updatePosition }: Props) => {
  const dispatch = useAppDispatch();
  const [itemList, setItemList] = useState<IDraggableElement[]>(elements);

  const handleDrop = async (droppedItem: any) => {
    dispatch(generalActions.setDisabledLoading(true));
    if (!droppedItem.destination) return;

    const currItem = itemList[droppedItem.source.index];

    updatePosition(currItem.id, droppedItem.destination.index);
    setItemList((updatedList) => {
      if (!droppedItem.destination) return updatedList;
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

      return updatedList;
    });
    // dispatch(generalActions.setDisabledLoading(false));
  };

  useEffect(() => {
    setItemList(elements);
  }, [elements]);

  return (
    <div className="draggable-list">
      <React.Fragment>
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="droppable-list-container">
            {(provided) => (
              <div className="draggable-list-container" {...provided.droppableProps} ref={provided.innerRef}>
                {itemList.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        className="draggle-item-container"
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                      >
                        {item.components}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </React.Fragment>
    </div>
  );
};

export default DraggableList;
