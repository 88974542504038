import { useNavigate } from 'react-router-dom';
import Card from '../../components/containers/card/Card';
import CardList from '../../components/containers/card/CardList';
import Separator from '../../components/generic/separator/Separator';
import { farmActions, useAppDispatch } from '../../store';
import { IFarm } from '../../types';
import AddFarm from './AddFarm';
import DashboardMovements from './components/DashboardMovements';
import DashboardStats from './components/DashboardStats';

interface Props {
  farms?: IFarm[];
  getAllFarms: () => void;
}

const DashboardList = ({ farms = [], getAllFarms }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleClick = (id: number | string) => {
    const farm = farms.find((farm) => farm.id === id);
    if (!farm) return;
    dispatch(farmActions.setFarm(farm));
    navigate(`/farm/${id.toString()}`);
  };
  return (
    <CardList>
      {farms?.map((farm) => (
        <Card key={farm.id} className="hoverable" onClick={() => handleClick(farm.id)}>
          <div className="title-container">
            <h2>{farm.name}</h2>
          </div>
          <Separator />
          <DashboardStats stats={farm.stats} />
          <Separator />
          <DashboardMovements movements={farm.movements} />
        </Card>
      ))}
      <AddFarm getAllFarms={getAllFarms} />
    </CardList>
  );
};

export default DashboardList;
