import React from 'react';
import './HorizontalContainer.css';

export interface IProps {
  children: React.ReactNode;
  className?: string;
  ref?: React.RefObject<HTMLDivElement>;
}
const HorizontalContainer: React.FC<IProps> = ({ children, className = '', ref }) => {
  return (
    <div className={`horizontal-container ${className}`} ref={ref}>
      {children}
    </div>
  );
};

export default HorizontalContainer;
