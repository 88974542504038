import { userActions } from './../store/user.slice';
import axiosOriginal from 'axios';
import { authService } from '../service';
import { API } from '../service/endpoints';
import { store } from '../store';

const axiosWrapper = () => {
  const axios = axiosOriginal.create({
    withCredentials: true,
    baseURL: API,
  });

  axios.post = makeAxiosWrapper(axios.post, axios);
  axios.get = makeAxiosWrapper(axios.get, axios);
  axios.delete = makeAxiosWrapper(axios.delete, axios);
  axios.put = makeAxiosWrapper(axios.put, axios);
  return axios;
};

const makeAxiosWrapper =
  (_func: any, _axios: any) =>
  async (...args: any[]) => {
    const catchedArgs = args.map((arg) => arg);
    try {
      const res = await _func.apply(_axios, catchedArgs);
      return res;
    } catch (error: any) {
      if (!error?.response?.status || error?.response?.status !== 401) {
        store.dispatch(userActions.logout());
        throw error;
      }
      const user = localStorage.getItem('user') || sessionStorage.getItem('user');
      if (!user || !JSON.parse(user)?.refreshToken) throw Error('no user found');

      try {
        await authService.refreshToken(JSON.parse(user)?.refreshToken);
        const res = await _func.apply(_axios, catchedArgs);
        return res;
      } catch (error: any) {
        store.dispatch(userActions.logout());
        throw error;
      }
    }
  };
export default axiosWrapper;
