import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { FormProps } from '../../../components/generic/form/Form';
import Button from '../../../components/inputs/buttons/Button';
import { categoryService } from '../../../service/category.service';
import { BUTTON_TYPE, FORM_INPUT_TYPE, ICategories, IFormInput } from '../../../types';

export const editCategoriesForm = (
  navigate: NavigateFunction,
  farmId: number | string,
  category?: ICategories,
): FormProps<ICategories>[] => {
  const inputs: IFormInput[] = [];
  const goBack = () => navigate(`/farm/${farmId}/categories`);
  const handleNewInput = () => {
    setForm((prev) => {
      const newInputs = [
        ...(prev[1].inputs || []),
        {
          id: `subNew${prev[1].inputs?.length || 0}`,
          label: 'sub categoria',
          placeholder: 'Sub Categoria',
          required: true,
          validate: (value: string) => !!value.length,
          errorMessage: 'Porfavor ingresar el nombre de sub categoria',
          type: FORM_INPUT_TYPE.TEXT,
        },
      ];
      prev[1].inputs = newInputs;
      return [...prev];
    });
  };

  const loadSubCategories = () => {
    if (!category?.subCategories?.length) return;

    category?.subCategories.forEach((subCategory, index) => {
      inputs.push({
        id: `subCategory${subCategory.id || index}`,
        label: 'sub categoria',
        placeholder: 'Sub Categoria',
        required: true,
        validate: (value: string) => !!value.length,
        errorMessage: 'Porfavor ingresar el nombre de sub categoria',
        type: FORM_INPUT_TYPE.TEXT,
        defaultValue: subCategory.name,
      });
    });
  };
  // loadSubCategories();

  const [form, setForm] = useState<FormProps<ICategories>[]>([]);

  const handleLoadCategory = () => {
    if (!category) return;
    setForm([
      {
        inputs: [
          {
            id: 'name',
            label: 'Nombre de categoria',
            placeholder: 'Nombre',
            required: true,
            validate: (value: string) => !!value.length,
            errorMessage: 'Porfavor ingresar el nombre de categoria',
            defaultValue: category?.name,
          },
        ],
        buttons: [
          {
            className: 'secondary',
            text: 'cancelar',
            type: BUTTON_TYPE.BUTTON,
            onClick: () => {
              goBack();
            },
          },
          {
            text: 'continuar',
            type: BUTTON_TYPE.SUBMIT,
          },
        ],
        onSubmit: async (data: any) => {
          const isCategoryDb = await categoryService.getAll({ ...data[0], farmId, isExactName: true });
          if (isCategoryDb.data?.filter((c) => c.id !== category?.id).length >= 1)
            throw new Error('Ya existe una Categoria con ese nombre');
        },
      },
      {
        inputs,
        children: (
          <Button onClick={handleNewInput} text="agregar sub categoria" className="add-sub-categories" />
        ),
        buttons: [
          {
            className: 'secondary',
            text: 'cancelar',
            type: BUTTON_TYPE.BUTTON,
            onClick: () => {
              goBack();
            },
          },
          {
            text: 'continuar',
            type: BUTTON_TYPE.SUBMIT,
          },
        ],
        onSubmit: async (data: any) => {
          const subCategories = Object.keys(data[1]).map((key) => ({
            id: key.split('subCategory')[1] || '',
            name: data[1][key],
          }));
          const transformedData = {
            ...data[0],
            subCategories,
            farmId,
          };

          await categoryService.update({ ...category, ...transformedData });
          setForm([]);
          goBack();
        },
      },
    ]);
  };

  useEffect(() => {
    loadSubCategories();
    handleLoadCategory();
  }, [category]);

  return form;
};
