import React, { FC } from 'react';
import './CenterContainer.css';
interface Props {
  children: React.ReactNode;
  className?: string;
}
const CenterContainer: FC<Props> = ({ children, className }) => {
  return <div className={`center-container ${className}`}>{children}</div>;
};

export default CenterContainer;
