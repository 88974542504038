import FarmLayout from '../../components/farmLayout/FarmLayout';
import { useAppSelector } from '../../store';
import FarmDashboardList from './farmDashboardList/FarmDashboardList';

const FarmDashboard = () => {
  const farm = useAppSelector((state) => state.farm);

  return (
    <FarmLayout className="dashboard" headerChildren={<h2>{farm?.name}</h2>}>
      <FarmDashboardList />
    </FarmLayout>
  );
};

export default FarmDashboard;
