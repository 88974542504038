import { useAppSelector } from '../../../store';
import VerticalContainer from '../../containers/verticalContainer/VerticalContainer';
import Logo from '../../generic/Logo';
import HorizontalContainer from '../../horizontalContainer/HorizontalContainer';
import './Header.css';

interface Props {
  children?: React.ReactNode;
  className?: string;
}
const Header = ({ children, className = '' }: Props) => {
  const user = useAppSelector((state) => state.user);
  return (
    <HorizontalContainer className={`header-container ${className}`}>
      <HorizontalContainer className="children-container">{children}</HorizontalContainer>
      <VerticalContainer className="user-info">
        <h5>{user?.name || ''}</h5>
        <p>El Patron</p>
      </VerticalContainer>
    </HorizontalContainer>
  );
};

export default Header;
