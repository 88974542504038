import React, { useEffect } from 'react';
import { IFormInput } from '../../types';
import ErrorMessage from './errorMessage/ErrorMessage';
import './GenericInput.css';

const GenericInput: React.FC<IFormInput> = ({
  id,
  label,
  type = 'text',
  defaultValue,
  onChange,
  ref,
  errorMessage,
  placeholder,
  isNotValid,
  onTouched,
  touched,
  className = '',
  min,
  max,
}) => {
  const presentRef = ref || React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (!presentRef.current || !defaultValue) return;
    presentRef.current.value = defaultValue;
  }, [defaultValue]);
  return (
    <div className={`generic-input-container ${className}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type={type}
        id={id}
        name={id}
        onChange={onChange}
        ref={presentRef}
        defaultValue={defaultValue || ''}
        placeholder={placeholder}
        onBlur={onTouched}
        min={min}
        max={max}
      />
      {isNotValid && touched && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

export default GenericInput;
