import { useForm } from '../../../hooks/useForm';
import { IFormButton, IFormInput } from '../../../types/form.types';
import VerticalContainer from '../../containers/verticalContainer/VerticalContainer';
import HorizontalContainer from '../../horizontalContainer/HorizontalContainer';
import HandleButton from './buttons/HandleButton';
import HandleInput from './inputs/HandleInput';
import './Form.css';
import { useEffect, useState } from 'react';
import ErrorMessage from '../../inputs/errorMessage/ErrorMessage';
import { FetchError } from '../../../types';
export interface FormProps<T> {
  inputs?: IFormInput[];
  className?: string;
  inputClassName?: string;
  buttonClassName?: string;
  buttons?: IFormButton | IFormButton[];
  onSubmit?: (values: T) => void;
  children?: React.ReactNode;
}

const Form = <T extends object>({
  inputs,
  className = '',
  onSubmit,
  buttons,
  inputClassName = 'input-container',
  buttonClassName = 'button-container',
  children,
}: FormProps<T>) => {
  const { state, disabled, onChange, onSubmitForm, onTouched, updateForm } = useForm(inputs || []);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    const vals = onSubmitForm(e);
    try {
      if (onSubmit) await onSubmit(vals as T);
    } catch (e: unknown) {
      let message = (e as FetchError).response?.data?.message;
      message ||= (e as { message: string }).message;
      setErrorMessage(message || 'Error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateForm(inputs || []);
  }, [inputs]);

  return (
    <VerticalContainer className={`form ${className}`}>
      <form onSubmit={handleOnSubmit}>
        <VerticalContainer className={inputClassName}>
          {Object.values(state).map((input) => (
            <HandleInput input={{ onChange, ...input, onTouched }} key={input.id} />
          ))}
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </VerticalContainer>
        {children}
        <HorizontalContainer className={buttonClassName}>
          {Array.isArray(buttons) ? (
            buttons.map((button) => (
              <HandleButton {...button} key={button.text} disabled={disabled || loading} />
            ))
          ) : (
            <HandleButton {...buttons} key={buttons?.text || 'text'} disabled={disabled} />
          )}
        </HorizontalContainer>
      </form>
    </VerticalContainer>
  );
};

export default Form;
