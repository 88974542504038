import { NavigateFunction, useNavigate } from 'react-router-dom';
import { FormProps } from '../../components/generic/form/Form';
import { authService } from '../../service';
import { store, useAppDispatch, useAppSelector, userActions } from '../../store';
import { BUTTON_TYPE, FORM_INPUT_TYPE, IFormButton, IFormInput } from '../../types';
import { ICreatePassowrd, ILogin, IRegister } from '../../types/generic/login.types';
import { EMAIL_REGEXP } from '../../types/regexp';

export const registerFields = (navigate: NavigateFunction) => {
  const registerFields: IFormInput[] = [
    {
      id: 'email',
      label: 'Email',
      placeholder: 'Enter your email',
      required: true,
      validate: (value: string) => EMAIL_REGEXP.test(value),
      errorMessage: 'el email es requerido',
      type: FORM_INPUT_TYPE.TEXT,
    },
  ];

  const registerButtons: IFormButton[] = [
    {
      type: BUTTON_TYPE.SUBMIT,
      children: 'Continuar',
    },
    {
      type: BUTTON_TYPE.BUTTON,
      children: 'Ingresar',
      className: 'secondary',
      onClick: () => navigate('/login'),
    },
  ];

  const registerSetpForm: FormProps<IRegister>[] = [
    {
      inputs: registerFields,
      buttons: registerButtons,
      onSubmit: async (values: any) => {
        store.dispatch(userActions.logout());
        await authService.validateEmail(values[0].email);
        store.dispatch(userActions.setUser({ email: values[0].email }));
      },
    },
    {
      inputs: [
        {
          id: 'password',
          label: 'Contraseña',
          placeholder: 'Contraseña',
          required: true,
          validate: (value: string) => value.length > 5,
          errorMessage: 'La contraseña es requerido',
          type: FORM_INPUT_TYPE.PASSWORD,
        },
        {
          id: 'confirmPassword',
          label: 'Repetir Contraseña',
          placeholder: 'Contraseña',
          required: true,
          type: FORM_INPUT_TYPE.PASSWORD,
        },
      ],
      buttons: {
        type: BUTTON_TYPE.SUBMIT,

        children: 'Continuar',
        className: 'full-width',
      },
      onSubmit: async (values: any) => {
        if (
          !values[1].password ||
          !values[1].confirmPassword ||
          values[1].password !== values[1].confirmPassword
        )
          throw new Error('Las contraseñas no coinciden');
        const { user } = store.getState();
        if (!user || !user.email) throw new Error('No User found');

        await authService.register({
          email: user.email,
          password: values[1].password,
        });
      },
    },
    {
      buttons: {
        type: BUTTON_TYPE.SUBMIT,
        children: 'Ir a login',
        className: 'full-width',
      },
      children: (
        <h5>
          ¡Ya esta casi listo! <br /> Verifica tu electronico para terminar de crear tu cuenta
        </h5>
      ),
      onSubmit: async (values: IRegister) => {
        navigate('/login');
      },
    },
    {
      buttons: {
        type: BUTTON_TYPE.SUBMIT,
        children: 'Ir a login',
        className: 'full-width',
      },
      children: (
        <h5>
          ¡Cuenta verificada! <br />
        </h5>
      ),
      onSubmit: async (values: IRegister) => {
        navigate('/login');
      },
    },
  ];
  return { registerSetpForm };
};
