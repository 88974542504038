import React from 'react';
import './ErrorMessage.css';

export interface IErrorMessage {
  text?: string;
  children?: React.ReactNode;
}

const ErrorMessage: React.FC<IErrorMessage> = ({ text, children }) => {
  return <p className="error-message">{text || children}</p>;
};

export default ErrorMessage;
