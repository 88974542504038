import { ILogin, IRegister, IRegisterQuery } from './../types/generic/login.types';
import { axios } from '../handlers';
import axiosInstance from 'axios';
import { API, ENDPOINTS } from './endpoints/endpoints';

enum AUTH_ROUTES {
  LOGIN = '/login',
  LOGOUT = '/logout',
  GOOGLE_OAUTH = '/oauth/google',
  VALIDATE_EMAIL = '/validate-email',
  VALIDATE_TOKEN = '/validate-token',
  REFRESH_TOKEN = '/refresh-token',
  REGISTER = '/register',
}
class AuthService {
  public endpoint: string;
  constructor() {
    this.endpoint = `${ENDPOINTS.AUTH}`;
  }

  validateEmail = async (email: string) =>
    (await axios.post(`${this.endpoint}${AUTH_ROUTES.VALIDATE_EMAIL}`, { email })).data;

  refreshToken = async (refreshToken: string) =>
    (await axiosInstance.post(`${this.endpoint}${AUTH_ROUTES.REFRESH_TOKEN}`, { token: refreshToken })).data;
  validateToken = async (token: string) =>
    (await axios.post(`${this.endpoint}${AUTH_ROUTES.VALIDATE_TOKEN}`, { token })).data;

  register = async (user: IRegisterQuery) =>
    (await axios.post(`${this.endpoint}${AUTH_ROUTES.REGISTER}`, user)).data;

  login = async (user: ILogin) => (await axios.post(`${this.endpoint}${AUTH_ROUTES.LOGIN}`, user)).data;
  logout = async () => (await axios.post(`${this.endpoint}${AUTH_ROUTES.LOGOUT}`)).data;
  googleOAuth = async (credential: string) =>
    (await axios.post(`${this.endpoint}${AUTH_ROUTES.GOOGLE_OAUTH}`, { credential })).data;
}

export const authService = new AuthService();
