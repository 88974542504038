import React from 'react';
import { BUTTON_TYPE, IFormButton } from '../../../types';
import './Button.css';

const RedButton: React.FC<IFormButton> = ({
  className,
  onClick,
  text,
  children,
  type = BUTTON_TYPE.BUTTON,
  disabled,
}) => {
  return (
    <button onClick={onClick} className={`button red ${className}`} type={type} disabled={disabled}>
      {text}
      {children}
    </button>
  );
};

export default RedButton;
