import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CardLink from '../../../components/containers/card/CardLink';
import ListWithChildrenLine from '../../../components/generic/listhWithChildren/ListWithChildrenLine';
import Separator from '../../../components/generic/separator/Separator';
import { categoryService } from '../../../service';
import { IAnimal, ICategories, listProp } from '../../../types';

const FarmAnimalCard = () => {
  const { id } = useParams();
  const [data, setData] = useState<listProp[]>([]);

  const handleGetCats = async () => {
    if (!id) return;
    const { data } = await categoryService.getAll({ farmId: id });
    const result = data.map((cat) => ({
      name: cat.name,
      ...handleCatsChilds(cat),
    }));
    setData(result);
  };

  const handleCatsChilds = (cat: ICategories) =>
    cat.subCategories?.reduce(
      (acc, curr) => {
        if (!curr.animals) return acc;

        const value = handleAnimals(curr.animals);
        acc.value += value;
        acc.children.push({ name: curr.name, value });
        return acc;
      },
      { value: 0, children: [] } as {
        value: number;
        children: { name: string; value: number }[];
      },
    );

  const handleAnimals = (animals: IAnimal[]) => {
    return animals.reduce((acc, animal) => (acc += animal.quantity), 0);
  };
  useEffect(() => {
    handleGetCats();
  }, [id]);
  return (
    <CardLink to={'categories'} key={'movements'} className="wide-card">
      <div className="title-container">
        <h2>{'Animales'}</h2>
      </div>
      <Separator />
      {data?.map((movement, i) => (
        <ListWithChildrenLine {...movement} />
      ))}
    </CardLink>
  );
};

export default FarmAnimalCard;
