import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Card from '../../../components/containers/card/Card';
import HorizontalContainer from '../../../components/horizontalContainer/HorizontalContainer';
import Button from '../../../components/inputs/buttons/Button';
import RedButton from '../../../components/inputs/buttons/Redbutton';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import EditableTable from '../../../components/table/EditableTable';
import { paddockService } from '../../../service';
import { animalService } from '../../../service/animal.service';
import { IAnimal, IAnimalColumn, IPaddock, ITableColumn, IObservations } from '../../../types';
import { paddockHelper } from './paddock.helper';
import { PaddockObservations } from './PaddockObservations';

export interface IProps {
  paddock: IPaddock & { value: number };
  getPaddocks: () => Promise<void>;
  options: { value: string; label: string }[];
  saidOptions: { value: string; label: string }[];
  activeColumns: IAnimalColumn[];
}

const PaddockCard = ({ paddock, getPaddocks, options, saidOptions, activeColumns }: IProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPaddockErrors, setIsPaddockErrors] = useState(false);
  const columns: ITableColumn<IAnimal>[] = paddockHelper.getColumns(
    options,
    saidOptions,
    activeColumns,
    false,
  );
  const [data, setData] = useState<IAnimal[]>([]);
  const [observations, setObservations] = useState<IObservations[]>([]);

  const handleDelete = async () => {
    if (paddock.id) await paddockService.delete(paddock.id);
    getPaddocks();
  };

  const setDeleting = async () => {
    await paddockService.delete(paddock.id);
    setIsDeleting((p) => !p);
  };

  const handleSave = async () => {
    const transfromedData: Partial<IAnimal>[] = data.map((d: Partial<IAnimal>) => {
      if (d.id && parseInt(d.id.toString(), 10) < 0) delete d.id;
      return d;
    });
    const transformedObservation: Partial<IObservations>[] = observations.map((d: Partial<IObservations>) => {
      if (d.id && parseInt(d.id.toString(), 10) < 0) delete d.id;
      return d;
    });
    await paddockService.update({
      ...paddock,
      animals: transfromedData,
      observations: transformedObservation,
    });
    await getPaddocks();
    setIsEditing((p) => false);
  };

  const handleEdit = () => {
    setIsEditing((p) => !p);
  };

  useEffect(() => {
    const conflicts = paddock.animals?.filter((a) => Number(a.quantity) < 0);
    if (conflicts && conflicts.length > 0) setIsPaddockErrors(true);
    setData(paddock.animals || []);
    setObservations(paddock.observations || []);
  }, [paddock]);

  const handleAnimalDelete = async (id: string | number) => {
    await animalService.delete(id);
    getPaddocks();
  };

  const handleOnChange = (value: IAnimal) => {
    const prevState = data.find((d) => d.id === value.id);

    if (prevState?.startData !== value.startData)
      value.permanenceDate = dayjs(value.startData, 'YYYY-MM-DD').add(90, 'd').format('YYYY-MM-DD');
    else if (prevState?.permanenceDate !== value.permanenceDate)
      value.startData = dayjs(value.permanenceDate, 'YYYY-MM-DD').add(-90, 'd').format('YYYY-MM-DD');

    return value;
  };
  return (
    <>
      <Card type="ultra-wide" className="paddock-card">
        <HorizontalContainer>
          <HorizontalContainer className="paddocks-card-title">
            <h2 className={isPaddockErrors ? 'error' : ''}>{paddock.name}</h2>
            <h2 className={isPaddockErrors ? 'error' : ''}>{paddock.value}</h2>
          </HorizontalContainer>
          <HorizontalContainer className="paddock-card-buttons">
            {!isEditing ? (
              <Button className="secondary" onClick={handleEdit}>
                Editar
              </Button>
            ) : (
              <Button onClick={handleSave}>Guardar</Button>
            )}
            {!isEditing ? (
              <RedButton onClick={setDeleting}>Eliminar</RedButton>
            ) : (
              <RedButton onClick={handleEdit} className="fill">
                Cancelar
              </RedButton>
            )}
          </HorizontalContainer>
        </HorizontalContainer>
        <EditableTable<IAnimal>
          data={data as unknown as IAnimal[]}
          isEditing={false}
          columns={columns}
          setData={setData}
          handleDelete={handleAnimalDelete}
          addText="Agregar Animal"
          onChange={handleOnChange}
        />
        <PaddockObservations
          observations={observations}
          setObservations={setObservations}
          isEditing={isEditing}
          getPaddocks={getPaddocks}
        />
      </Card>
      <ConfirmModal
        text={`El Potrero ${paddock.name} y sus ${(paddock.animals || []).length} animales se eliminaran `}
        title={'Confirmar'}
        showModal={isDeleting}
      >
        <HorizontalContainer>
          <RedButton onClick={handleDelete}>Eliminar</RedButton>
          <Button className="secondary" onClick={setDeleting}>
            Cancelar
          </Button>
        </HorizontalContainer>
      </ConfirmModal>
    </>
  );
};

export default PaddockCard;
