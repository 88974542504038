import CardList from '../../../components/containers/card/CardList';
import FarmMovement from './FarmMovement';
import './FarmDashboardList.css';
import FarmStatsCard from './FarmStatsCard';
import FarmAnimalCard from './FarmAnimalCard';
import FarmPaddockCard from './FarmPaddockCard';

const FarmDashboardList = () => {
  return (
    <CardList className={'farm-dashboard-list'}>
      <FarmMovement />
      <FarmStatsCard />
      <FarmAnimalCard />
      <FarmPaddockCard />
    </CardList>
  );
};

export default FarmDashboardList;
