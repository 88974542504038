import CardLink from '../../../components/containers/card/CardLink';
import { addIcon } from '../../../images/icons';
import VerticalContainer from '../verticalContainer/VerticalContainer';

interface Props {
  cardType?: 'wide' | 'normal' | 'ultra-wide';
  title: string;
  text: string;
  to?: string;
}
const CardLinkAdd = ({ cardType, text, title, to = 'add' }: Props) => {
  return (
    <CardLink type={cardType} to={to} className="card-add">
      <>
        <VerticalContainer className="no-gap">
          <h2>{title}</h2>
          {text && <p>{text}</p>}
        </VerticalContainer>
        <div className="icon-container">
          <img src={addIcon} alt="add icon" />
        </div>
      </>
    </CardLink>
  );
};

export default CardLinkAdd;
