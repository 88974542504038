import { IFarm, ICategories } from './../types/entities.types';
import BaseService from './base.service';
import { ENDPOINTS } from './endpoints';

export class CategoryService extends BaseService<ICategories> {
  constructor() {
    super(ENDPOINTS.CATEGORIES);
  }
}

export const categoryService = new CategoryService();
