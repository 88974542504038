import './Separator.css';

interface Props {
  className?: string;
}
const Separator = ({ className = '' }: Props) => {
  return <hr className={`separator ${className}`} />;
};

export default Separator;
