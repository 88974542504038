import { useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { FormProps } from '../../../components/generic/form/Form';
import { paddockService } from '../../../service';
import { BUTTON_TYPE, ICategories, IFormInput } from '../../../types';

let options: { value: string; label: string }[] = [];
const inputs: IFormInput[] = [];
export const addPaddockForm = (navigate: NavigateFunction, farmId: number | string) => {
  const goBack = () => navigate(`/farm/${farmId}/paddock`);
  // const handleNewInput = () => {
  //   setForm((prev) => {
  //     const newInputs = [
  //       ...(form[1].inputs || []),
  //       {
  //         id: `subCategory${prev[1].inputs?.length || 0}`,
  //         label: 'sub categoria',
  //         placeholder: 'Sub Cateegoria',
  //         required: true,
  //         validate: (value: string) => !!value.length,
  //         errorMessage: 'Porfavor ingresar el nombre de sub categoria',
  //         type: FORM_INPUT_TYPE.SELECT,
  //         options,
  //         isNotValid: true,
  //       },
  //       {
  //         id: `subCategory${prev[1].inputs?.length || 0}Animals`,
  //         label: 'cantidad',
  //         placeholder: '0',
  //         required: true,
  //         validate: (value: string) => parseInt(value, 10) > 0,
  //         errorMessage: 'debe ser mayor a 0',
  //         type: FORM_INPUT_TYPE.NUMBER,
  //       },
  //     ];
  //     prev[1].inputs = newInputs;
  //     return [...prev];
  //   });
  // };
  const [form, setForm] = useState<FormProps<ICategories>[]>([
    {
      inputs: [
        {
          id: 'name',
          label: 'Nombre del potrero',
          placeholder: 'Nombre',
          required: true,
          validate: (value: string) => !!value.length,
          errorMessage: 'Porfavor ingresar el nombre del potrero',
        },
      ],
      buttons: [
        {
          className: 'secondary',
          text: 'cancelar',
          type: BUTTON_TYPE.BUTTON,
          onClick: () => {
            goBack();
          },
        },
        {
          text: 'continuar',
          type: BUTTON_TYPE.SUBMIT,
        },
      ],
      onSubmit: async (data: any) => {
        const isPaddockInDb = await paddockService.getAll({ ...data[0], farmId, isExactName: true });
        if (isPaddockInDb.data?.length) throw new Error('Ya existe un potrero con ese nombre');
        const transformedData = {
          ...data[0],
          farmId,
        };
        await paddockService.create(transformedData);
        goBack();
      },
    },
    // {
    //   className: 'grid-add-paddock',
    //   inputs,
    //   children: (
    //     <Button onClick={handleNewInput} text="agregar sub categoria" className="add-sub-categories" />
    //   ),
    //   buttons: [
    //     {
    //       className: 'secondary',
    //       text: 'cancelar',
    //       type: BUTTON_TYPE.BUTTON,
    //       onClick: () => {
    //         goBack();
    //       },
    //     },
    //     {
    //       text: 'continuar',
    //       type: BUTTON_TYPE.SUBMIT,
    //     },
    //   ],
    //   onSubmit: async (data: any) => {
    //     const dataSecondStep: any = [];

    //     const subCategories = Object.values(data[1]);
    //     for (let i = 0; i < subCategories.length; i += 2) {
    //       dataSecondStep.push({
    //         subCategoryId: subCategories[i],
    //         quantity: subCategories[i + 1],
    //       });
    //     }

    //     const transformedData = {
    //       ...data[0],
    //       animals: dataSecondStep,
    //       farmId,
    //     };
    //     await paddockService.create(transformedData);
    //     goBack();
    //   },
    // },
  ]);

  const setOptions = (opt: { value: string; label: string }[]) => {
    options = opt;
  };
  return { form, setOptions };
};
