import { useParams } from 'react-router-dom';
import { farmsIcon, movementIcon, settingsIcon, fenceIcon, pawIcon, houseIcon } from '../../images/icons';
import LinkIcon from '../inputs/linkIcon/LinkIcon';
import Sidebar from '../layout/sidebar/Sidebar';

const FarmSidebar = () => {
  const { id } = useParams();

  return (
    <Sidebar>
      <h4>Dashboard</h4>
      <LinkIcon to={`/farm/${id}`} text="Dashboard" icon={houseIcon} activeclassName="active" />
      <LinkIcon
        to={`/farm/${id}/movements/pending`}
        text="Movimientos"
        icon={movementIcon}
        activeclassName="active"
        activePath="movements"
      />
      <LinkIcon to={`/farm/${id}/paddock`} text="Potreros" icon={fenceIcon} activeclassName="active" />
      <LinkIcon to={`/farm/${id}/categories`} text="Categorias" icon={pawIcon} activeclassName="active" />
      <LinkIcon to={'/dashboard'} text="Establecimientos" icon={farmsIcon} activeclassName="active" />
      <LinkIcon to={`/farm/${id}/settings`} text="ajustes" icon={settingsIcon} activeclassName="active" />
    </Sidebar>
  );
};

export default FarmSidebar;
