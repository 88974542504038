import React, { RefObject } from 'react';
import { IFormInput } from '../../types';
import ErrorMessage from './errorMessage/ErrorMessage';

const TextAreaInput: React.FC<IFormInput> = ({
  id,
  label,
  defaultValue,
  onChange,
  ref,
  errorMessage,
  placeholder,
  isNotValid,
  onTouched,
  touched,
  className = '',
}) => {
  const presentRef = ref || React.createRef<HTMLInputElement>();
  return (
    <div className={`generic-input-container textarea-container ${className}`} key={id}>
      {label && <label htmlFor={id}>{label}</label>}
      <textarea
        id={id}
        name={id}
        onChange={onChange as unknown as (event: React.ChangeEvent<HTMLTextAreaElement>) => void}
        ref={presentRef as unknown as RefObject<HTMLTextAreaElement>}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onBlur={onTouched as unknown as (event: React.FocusEvent<HTMLTextAreaElement>) => void}
      />
      {isNotValid && touched && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

export default TextAreaInput;
