import { IFarm } from './../types/entities.types';
import { createSlice } from '@reduxjs/toolkit';
import { IAction } from '../types/store.types';

const initialState: IFarm | null = JSON.parse(
  localStorage.getItem('farm') || sessionStorage.getItem('farm') || 'null',
);

const farmSlice = createSlice({
  name: 'farm',
  initialState,
  reducers: {
    setFarm: (state, action: IAction<IFarm>) => {
      const newState = { ...state, ...action.payload };
      localStorage.setItem('farm', JSON.stringify(newState));
      return newState;
    },
    clearFarm: (state) => {
      localStorage.removeItem('farm');
      return null;
    },
  },
});
export const farmActions = farmSlice.actions;
export default farmSlice;
