import { useEffect, useState } from 'react';
import { searchIcon } from '../../../images/icons';
import { generalActions, useAppDispatch } from '../../../store';
import InputIcon from '../InputIcon';
import './SearchInput.css';

interface Props {
  placeholder?: string;
  handleSearch: (value: string) => Promise<void> | void;
}
const SearchInput = ({ placeholder = 'buscar', handleSearch }: Props) => {
  const [value, setValue] = useState('');
  const [isTimeOut, setIsTimeOut] = useState(false);
  const dispatch = useAppDispatch();
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(generalActions.setDisabledLoading(true));
    setValue(e.target.value);
    if (isTimeOut) return;
    setIsTimeOut(true);
    wrapperHandleSearch;
    setTimeout(() => setIsTimeOut(false), 2000);
  };

  const wrapperHandleSearch = async (value: string) => {
    dispatch(generalActions.setDisabledLoading(true));
    await handleSearch(value);
    dispatch(generalActions.setDisabledLoading(false));
  };
  useEffect(() => {
    if (isTimeOut) return;
    wrapperHandleSearch(value);
  }, [isTimeOut]);

  return (
    <>
      <InputIcon id={''} img={searchIcon} placeholder={placeholder} onChange={handleChange} />
    </>
  );
};

export default SearchInput;
