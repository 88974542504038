import { useState, ChangeEvent, useEffect } from 'react';
import Card from '../../../components/containers/card/Card';
import HorizontalContainer from '../../../components/horizontalContainer/HorizontalContainer';
import SelectInput from '../../../components/inputs/selectInput/SelectInput';
import { IPaddock } from '../../../types';
import {
  MOVEMENTS_TYPE,
  showOriginMovements,
  showDestinyMovements,
  movementsOptions,
} from '../../../types/movements.types';

interface IProps {
  paddocks: IPaddock[];
  operationType?: MOVEMENTS_TYPE;
  setOperationType: (type: MOVEMENTS_TYPE) => void;
  setSelectedPaddockOrigin: (paddock: IPaddock) => void;
  setSelectedPaddockDestiny: (paddock: IPaddock) => void;
}
const MovementsOptions = ({
  paddocks,
  operationType,
  setSelectedPaddockOrigin,
  setSelectedPaddockDestiny,
  setOperationType,
}: IProps) => {
  const [isShowingOrigin, setIsShowingOrigin] = useState<boolean>(false);
  const [isShowingDestiny, setIsShowingDestiny] = useState<boolean>(false);
  const handleOperationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as MOVEMENTS_TYPE;
    setOperationType(value);
  };
  const handleIsShowingOrigin = () => {
    if (operationType && showOriginMovements.includes(operationType)) setIsShowingOrigin(true);
    else setIsShowingOrigin(false);
  };
  const handleIsShowingDestiny = () => {
    if (operationType && showDestinyMovements.includes(operationType)) setIsShowingDestiny(true);
    else setIsShowingDestiny(false);
  };
  const handleSelectPaddockDestiny = (e: ChangeEvent<HTMLSelectElement>) => {
    const paddockIndex = paddocks.findIndex((p) => p.id == e.target.value);
    if (paddockIndex < 0) return;
    setSelectedPaddockDestiny(paddocks[paddockIndex]);
  };

  const handleSelectPaddockOrigin = (e: ChangeEvent<HTMLSelectElement>) => {
    const paddockIndex = paddocks.findIndex((p) => p.id == e.target.value);
    if (paddockIndex < 0) return;
    setSelectedPaddockOrigin(paddocks[paddockIndex]);
  };

  useEffect(() => {
    handleIsShowingOrigin();
    handleIsShowingDestiny();
  }, [operationType]);
  return (
    <Card type="ultra-wide" className="options">
      <HorizontalContainer>
        <SelectInput
          id="operation-type"
          options={movementsOptions}
          label={'Tipo de Operacion'}
          onChange={handleOperationChange}
        ></SelectInput>
        {isShowingOrigin && (
          <SelectInput
            id="origin-paddock"
            options={paddocks.map((p) => ({ label: p.name, value: p.id }))}
            label={'Origen'}
            onChange={handleSelectPaddockOrigin}
          ></SelectInput>
        )}
        {isShowingDestiny && (
          <SelectInput
            id="destination-paddock"
            options={paddocks.map((p) => ({ label: p.name, value: p.id }))}
            label={'Destino'}
            onChange={handleSelectPaddockDestiny}
          ></SelectInput>
        )}
      </HorizontalContainer>
    </Card>
  );
};

export default MovementsOptions;
