import VerticalContainer from '../containers/verticalContainer/VerticalContainer';
import './Slogan.css';
interface IProps {
  children?: React.ReactNode;
  className?: string;
  secondarytext?: string;
}
const Slogan: React.FC<IProps> = ({ children, className, secondarytext }) => (
  <VerticalContainer className="slogan-container">
    <h3 className={className || ''}>
      Todo lo que necesitas para <br />
      organizar tu campo
    </h3>
    <p>{secondarytext}</p>
    {children}
  </VerticalContainer>
);

export default Slogan;
