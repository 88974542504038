import qs from 'qs';
import { axios } from '../handlers';
import { PaginatedResponse, Params } from '../types';
import { ENDPOINTS } from './endpoints';

export default class MovementsService {
  public endpoint: () => string;
  constructor(endpoint: () => string) {
    this.endpoint = endpoint;
  }

  public getAll = async (farmId: string | number) => {
    const elem = await axios.get(this.endpoint());
    return elem.data as PaginatedResponse<{ message: string }[]>;
  };
}

export const movementsService = new MovementsService(ENDPOINTS.MOVEMENTS);
