import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { PORTAL_HANDLER } from '../../types';
import Separator from '../generic/separator/Separator';
import Modal from './Modal';

type Props = {
  text: string;
  title: string;
  children: React.ReactNode;
  showModal: boolean;
};
const ConfirmModal = ({ text, title = '', children, showModal }: Props) => {
  const [isClosing, setisClosing] = useState(false);
  const root = document.getElementById(PORTAL_HANDLER.CONFIRM_MODAL) as HTMLElement;

  useEffect(() => {
    if (showModal) setisClosing(true);
    else
      setTimeout(() => {
        setisClosing(false);
      }, 600);
  }, [showModal]);

  const content = (
    <>
      {(showModal || isClosing) && (
        <Modal className="confirm-modal" isClosing={!showModal}>
          <h2>{title}</h2>
          <Separator />
          <h4>{text}</h4>
          {children}
        </Modal>
      )}
    </>
  );
  return ReactDOM.createPortal(content, root);
};

export default ConfirmModal;
