import React from 'react';
import VerticalContainer from '../containers/verticalContainer/VerticalContainer';
import Separator from './separator/Separator';

interface Props {
  className?: string;
  children?: React.ReactNode;
  text?: string;
  title?: string;
  isShowSeparator?: boolean;
}
const TitleSubTitle = ({
  className = '',
  children,
  text = '',
  title = '',
  isShowSeparator = false,
}: Props) => {
  return (
    <VerticalContainer className="slogan-container">
      <h3 className={className || ''}>{title}</h3>
      {isShowSeparator && <Separator />}
      <p>{text}</p>
      {children}
    </VerticalContainer>
  );
};

export default TitleSubTitle;
