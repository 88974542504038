import { MOVEMENTS_TYPE } from './movements.types';

export interface IUser {
  id: string;
  email: string;
  name: string;
  role: string;
  farmUserRoles: {
    farmId: string;
    userId: string;
    role: { id: string | number; name: string };
  }[];
}

export interface IUserSlice extends Partial<IUser> {
  authToken?: string;
  refreshToken?: string;
  registerToken?: string;
}

export interface IFarm {
  name: string;
  id: number | string;
  stats: {
    name: string;
    value: string | number;
  }[];
  movements: { message: string }[];
}

export interface ISubCategory {
  name: string;
  id: string;
  animals?: IAnimal[];
}
export interface ICategories {
  name: string;
  id: string;
  farm: string | number;
  subCategories: ISubCategory[];
}

export interface IPaddock {
  name: string;
  id: string;
  farm: string | number;
  subCategories: ISubCategory[];
  animals: IAnimal[];
  value: number;
  observations?: IObservations[];
}

export interface IAnimals {
  id: string;
  quantity: number;
  paddockId: string;
  subCategory: ISubCategory;
}

export interface IAnimal {
  said: ISaid;
  saidId: string | number;
  quantity: number;
  startData: string;
  permanenceDate: string;
  KLL: string;
  purchasePrice: string;
  id: string | number;
  paddockId?: string;
  subCategoryId: string | number;
  subCategory?: ISubCategory;
  type?: ANIMAL_TYPE | MOVEMENTS_TYPE;
}

export interface IAnimalMovements extends IAnimal {
  quantityToMove: number;
}

export interface IAnimalTable extends IAnimal {
  selected?: boolean;
}

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export interface ISaid {
  id: string | number;
  name: string;
  animals: IAnimal[];
  serial: string;
}

export enum ANIMAL_TYPE {
  ACTIVE = 0,
  PENDING = 1,
}

export interface IAnimalColumn {
  id: string | number;
  farmId: string | number;
  name: string;
  isActive: boolean;
}

export interface IObservations {
  id: string | number;
  value: string;
  paddockId: string | number;
}

export interface IFile {
  url: string;
  name: string;
  id: string | number;
  extension: string;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
}
