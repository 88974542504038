import { useEffect, useState } from 'react';
import CardButton from '../../components/containers/card/CardButton';
import Form from '../../components/generic/form/Form';
import { addIcon } from '../../images/icons';
import { farmService } from '../../service/farm.service';
import { getFields } from './AddFarm.Fields';

export interface Props {
  getAllFarms: () => void;
}

const AddFarm = ({ getAllFarms }: Props) => {
  const [addFarm, setAddFarm] = useState(false);
  const { inputs, buttons } = getFields(setAddFarm);
  const handleAddFarmClick = () => {
    setAddFarm(true);
  };

  const onSubmit = async (values: any) => {
    await farmService.create(values);
    setAddFarm(false);
    getAllFarms();
  };

  return (
    <CardButton className="add-card farm-add-card" onClick={!addFarm ? handleAddFarmClick : undefined}>
      {!addFarm ? (
        <>
          <h2>Agregar Establecimiento</h2>
          <div className="icon-container">
            <img src={addIcon} alt="add icon" />
          </div>
        </>
      ) : (
        <Form<{ name: string }> inputs={inputs} buttons={buttons} onSubmit={onSubmit} />
      )}
    </CardButton>
  );
};

export default AddFarm;
