import { configureStore } from '@reduxjs/toolkit';
import farmSlice from './farm.slice';
import generalSlice from './general.slice';
import userSlice from './user.slice';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    farm: farmSlice.reducer,
    general: generalSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
