import {
  FORM_INPUT_TYPE,
  IAnimal,
  IAnimalColumn,
  IAnimalMovements,
  IOption,
  ITableColumn,
  PaddocksColumn,
} from '../../../types';

class PaddockHelper {
  getColumnsSettings = (): PaddocksColumn[] => [
    {
      displayName: 'Categoria',
      key: 'subCategoryId',
      id: 'subCategoryId',
      type: FORM_INPUT_TYPE.SELECT,
      defaultValue: 'Categoria',
      isActive: true,
    },
    {
      displayName: 'Diocse',
      type: FORM_INPUT_TYPE.SELECT,
      key: 'saidId',
      id: 'saidId',
      defaultValue: '',
      isActive: true,
    },

    //   displayName: 'Cantidad',
    //   type: FORM_INPUT_TYPE.NUMBER,
    //   key: 'quantity',
    //   id: 'quantity',
    //   isActive: true,
    // },
    {
      displayName: 'Ingreso',
      type: FORM_INPUT_TYPE.DATE,
      key: 'startData',
      id: 'startData',
      isActive: true,
    },
    {
      displayName: 'Permanencia',
      type: FORM_INPUT_TYPE.DATE,
      key: 'permanenceDate',
      id: 'permanenceDate',
      isActive: true,
    },
    {
      displayName: 'KLL',
      type: FORM_INPUT_TYPE.NUMBER,
      key: 'KLL',
      id: 'KLL',
      isActive: true,
    },
    {
      displayName: 'Precio Compra',
      type: FORM_INPUT_TYPE.NUMBER,
      key: 'purchasePrice',
      id: 'purchasePrice',
      isActive: true,
    },
  ];
  getColumns = (
    options: IOption[],
    saidOptions: IOption[],
    activeColumns?: IAnimalColumn[],
    keyPath = true,
  ): ITableColumn<IAnimal>[] => {
    const columns: ITableColumn<IAnimal>[] = [
      {
        displayName: 'Categoria',
        key: 'subCategoryId',
        type: FORM_INPUT_TYPE.SELECT,
        options,
        defaultValue: 'Categoria',
        ...(keyPath ? { keyPath: 'subCategory.name' } : {}),
      },
      {
        displayName: 'Diocse',
        type: FORM_INPUT_TYPE.SELECT,
        options: saidOptions,
        key: 'saidId',
        ...(keyPath ? { keyPath: 'said.name' } : {}),
        defaultValue: '',
      },
      {
        displayName: 'Cantidad',
        type: FORM_INPUT_TYPE.NUMBER,
        key: 'quantity',
        isValidValue: (value) => Number(value) >= 0,
      },
      {
        displayName: 'Ingreso',
        type: FORM_INPUT_TYPE.DATE,
        key: 'startData',
      },
      {
        displayName: 'Permanencia',
        type: FORM_INPUT_TYPE.DATE,
        key: 'permanenceDate',
      },
      {
        displayName: 'KLL',
        type: FORM_INPUT_TYPE.NUMBER,
        key: 'KLL',
      },
      {
        displayName: 'Precio Compra',
        type: FORM_INPUT_TYPE.NUMBER,
        key: 'purchasePrice',
      },
    ];
    return this.filter(columns, activeColumns);
  };

  getPendingColumns = (
    categoryOptions: IOption[],
    paddockOptions: IOption[],
    activeColumns: IAnimalColumn[],
  ): ITableColumn<IAnimal>[] => {
    const columns: ITableColumn<IAnimal>[] = [
      {
        displayName: 'Categoria',
        key: 'subCategoryId',
        type: FORM_INPUT_TYPE.SELECT,
        options: categoryOptions,
        defaultValue: 'Categoria',
      },
      {
        displayName: 'Potrero',
        type: FORM_INPUT_TYPE.SELECT,
        key: 'paddockId',
        options: paddockOptions,
        defaultValue: 'Potrero',
      },
      {
        displayName: 'Cantidad',
        type: FORM_INPUT_TYPE.NUMBER,
        key: 'quantity',
      },
      {
        displayName: 'Ingreso',
        type: FORM_INPUT_TYPE.DATE,
        key: 'startData',
      },
      {
        displayName: 'Permanencia',
        type: FORM_INPUT_TYPE.DATE,
        key: 'permanenceDate',
      },
      {
        displayName: 'KLL',
        type: FORM_INPUT_TYPE.NUMBER,
        key: 'KLL',
      },
      {
        displayName: 'Precio Compra',
        type: FORM_INPUT_TYPE.NUMBER,
        key: 'purchasePrice',
      },
    ];
    return this.filter(columns, activeColumns);
  };

  getPendingCreateColumns = (): ITableColumn<IAnimalMovements>[] => {
    return this.filter([
      {
        displayName: 'Categoria',
        key: 'subCategoryId',
        defaultValue: 'Categoria',
        isLocked: true,
      },
      {
        displayName: 'Dicose',
        type: FORM_INPUT_TYPE.SELECT,
        key: 'paddockId',
        defaultValue: 'Potrero',
        isLocked: true,
      },
      {
        displayName: 'Cantidad',
        type: FORM_INPUT_TYPE.NUMBER,
        key: 'quantity',
        isLocked: true,
      },
      {
        displayName: 'Ingreso',
        type: FORM_INPUT_TYPE.DATE,
        key: 'startData',
        isLocked: true,
      },
      {
        displayName: 'Permanencia',
        type: FORM_INPUT_TYPE.DATE,
        key: 'permanenceDate',
        isLocked: true,
      },
      {
        displayName: 'KLL',
        type: FORM_INPUT_TYPE.NUMBER,
        key: 'KLL',
        isLocked: true,
      },
      {
        displayName: 'Precio Compra',
        type: FORM_INPUT_TYPE.NUMBER,
        key: 'purchasePrice',
        isLocked: true,
      },
      {
        displayName: 'Cantidad a Mover',
        type: FORM_INPUT_TYPE.NUMBER,
        key: 'purchasePrice',
        isLocked: true,
      },
    ]);
  };

  filter = <T>(columns: ITableColumn<T>[], activeColumns?: IAnimalColumn[]) =>
    !activeColumns
      ? columns
      : columns.filter(
          (column) => !activeColumns || activeColumns.find((c) => c.name === column.key && c.isActive),
        );
}

export const paddockHelper = new PaddockHelper();
