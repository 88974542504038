import { useEffect, useState } from 'react';
import Card from '../../../components/containers/card/Card';
import VerticalContainer from '../../../components/containers/verticalContainer/VerticalContainer';
import HorizontalContainer from '../../../components/horizontalContainer/HorizontalContainer';
import Button from '../../../components/inputs/buttons/Button';
import Table from '../../../components/table/EditableTable';
import { animalColumnService } from '../../../service';
import { FORM_INPUT_TYPE, IAnimalColumn, ITableColumn, PaddocksColumn } from '../../../types';
import { paddockHelper } from '../../farms/paddocks/paddock.helper';
import './ColumnsSettings.css';
const ColumnsSettings = () => {
  const [data, setData] = useState<PaddocksColumn[]>([]);
  const isEditing = false;

  const columns: ITableColumn<PaddocksColumn>[] = [
    { displayName: 'Nombre', key: 'displayName', type: FORM_INPUT_TYPE.TEXT },
    {
      displayName: 'Esta Activo',
      key: 'isActive',
      type: FORM_INPUT_TYPE.BOOLEAN,
    },
    // { displayName: 'Identificador', key: 'serial', type: FORM_INPUT_TYPE.TEXT },
  ];

  const getColumns = async () => {
    const reqData = await animalColumnService.getAll();
    const dataOut: PaddocksColumn[] = paddockHelper.getColumnsSettings().map((elem) => {
      const reqElem = reqData.data.find((e) => e.name === elem.id);
      return {
        ...elem,
        isActive: reqElem?.isActive || false,
        id: reqElem?.id?.toString() || '',
      };
    });
    setData(dataOut);
  };
  const onChange = (elem: PaddocksColumn) => {
    const updateRes = animalColumnService.update({ ...elem, isActive: elem.isActive.toString() == 'true' });
  };
  useEffect(() => {
    getColumns();
  }, []);
  return (
    <>
      <VerticalContainer className="column-settings">
        <Card>
          <HorizontalContainer className="title">
            <h2>Columnas de Animales</h2>
            <Button className="disabledFirstRealse">Agregar</Button>
          </HorizontalContainer>
          <Table<PaddocksColumn>
            data={data}
            isEditing={isEditing}
            isDeleteAlwaysOn={true}
            columns={columns}
            setData={setData}
            handleDelete={() => {}}
            addText="Agregar Columna"
            onChange={onChange}
          />
        </Card>
      </VerticalContainer>
    </>
  );
};

export default ColumnsSettings;
