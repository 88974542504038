import React from 'react';
import './VerticalContainer.css';

interface IProps {
  children: React.ReactNode;
  className?: string;
}
const VerticalContainer: React.FC<IProps> = ({ children, className }) => {
  return <div className={`vertical-container ${className}`}>{children}</div>;
};

export default VerticalContainer;
