import { useAppSelector } from '../../../../store';
import BlurComponentLoader from '../BlurComponentLoader';
import './CompletePageLoader.css';

const CompletePageLoader = () => {
  const isLoading = useAppSelector((state) => state.general.isLoading);
  const isDisabled = useAppSelector((state) => state.general.isLoadingDisabled);
  return <>{isLoading && !isDisabled ? <BlurComponentLoader className="complete-page-loader" /> : <></>}</>;
};

export default CompletePageLoader;
