import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CardLinkAdd from '../../../components/containers/card/CardLinkAdd';
import CardList from '../../../components/containers/card/CardList';
import SubCategoryCard from '../../../components/containers/card/SubCategoryCard';
import FarmLayout from '../../../components/farmLayout/FarmLayout';
import SearchInput from '../../../components/inputs/searchInput/SearchInput';
import { categoryService } from '../../../service/category.service';
import { useAppSelector } from '../../../store';
import { ICategories } from '../../../types';
import './Categories.css';

const Categories = () => {
  const { id } = useParams();
  const farm = useAppSelector((state) => state.farm);

  const [categories, setCategories] = useState<ICategories[]>([]);
  const [pagination, setPagination] = useState({ page: 1, totalPages: 0, take: 10 });
  const getCategories = async (value = '') => {
    const data = await categoryService.getAll({ farmId: id, name: value, ...pagination });
    setCategories(data.data);
    setPagination((p) => ({ ...pagination, totalPages: Math.ceil(data.total / p.take) }));
  };

  const handleDelete = async (id: string | number | undefined) => {
    if (id) await categoryService.delete(id);
    getCategories();
  };

  useEffect(() => {
    getCategories();
  }, [pagination.page, pagination.take, id]);

  return (
    <FarmLayout
      className="dashboard"
      title={farm?.name || ''}
      description="Categorias"
      headerChildren={<SearchInput handleSearch={getCategories} />}
    >
      <CardList className={'farm-dashboard-list'} setPagination={setPagination} pagination={pagination}>
        {categories.map((cat) => (
          <SubCategoryCard<ICategories> key={cat.id} data={cat} handleDelete={handleDelete} />
        ))}
        <CardLinkAdd
          title={'Agregar Categoria'}
          text={'Agregar nuevas categorias para separar sus distintos animales'}
          cardType={'wide'}
        />
      </CardList>
    </FarmLayout>
  );
};

export default Categories;
