import { IUserSlice } from './../types/entities.types';
import { createSlice } from '@reduxjs/toolkit';
import { IAction } from '../types/store.types';

const initialState: IUserSlice | null = JSON.parse(
  localStorage.getItem('user') || sessionStorage.getItem('user') || 'null',
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state = {};
      localStorage.removeItem('user');
      localStorage.removeItem('general');
      return state;
    },
    setUser: (state, action: IAction<IUserSlice>) => {
      const newState = { ...state, ...action.payload };
      const rememberMe = JSON.parse(localStorage.getItem('general') || '{}').rememberMe;
      if (rememberMe) localStorage.setItem('user', JSON.stringify(newState));
      else sessionStorage.setItem('user', JSON.stringify(newState));
      return newState;
    },
    loadUser: (state) => {
      const newState = JSON.parse(localStorage.getItem('user') || '{}');
      return newState;
    },
  },
});
export const userActions = userSlice.actions;
export default userSlice;
