import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Card from '../../../components/containers/card/Card';
import HorizontalContainer from '../../../components/horizontalContainer/HorizontalContainer';
import Button from '../../../components/inputs/buttons/Button';
import RedButton from '../../../components/inputs/buttons/Redbutton';
import EditableTable from '../../../components/table/EditableTable';
import { animalService } from '../../../service/animal.service';
import {
  ITableColumn,
  IAnimal,
  IAnimalTable,
  ISaid,
  ANIMAL_TYPE,
  IAnimals,
  IAnimalColumn,
} from '../../../types';
import { paddockHelper } from '../../farms/paddocks/paddock.helper';

export interface IProps {
  categoriesOptions?: { value: string; label: string }[];
  paddockOptions?: { value: string; label: string }[];
  said: ISaid;
  getSaids: () => Promise<void>;
  activeColumns: IAnimalColumn[];
}

const PendingMovementsCard = ({
  paddockOptions = [],
  categoriesOptions = [],
  said,
  getSaids,
  activeColumns,
}: IProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const columns: ITableColumn<IAnimal>[] = paddockHelper.getPendingColumns(
    categoriesOptions,
    paddockOptions,
    activeColumns,
  );
  const [data, setData] = useState<IAnimalTable[]>([]);
  const [error, setError] = useState<string>();

  const handleSelect = (id: string | number, selected?: boolean) => {
    const elmIndex = data.findIndex((p) => p.id == id);
    data[elmIndex].selected = selected || !data[elmIndex].selected;
    setData([...data]);
  };

  const handleSave = async () => {
    const transformAnimals = data.reduce((acc, animal) => {
      if (!animal.selected) return acc;
      const animalCopy = { ...animal };
      delete animalCopy.selected;

      return [...acc, { ...(animalCopy as IAnimal), type: ANIMAL_TYPE.ACTIVE } as IAnimal];
    }, [] as IAnimalTable[]);
    try {
      await Promise.all(transformAnimals.map((animal) => animalService.update(animal)));
      setIsEditing(false);
    } catch (err) {
      setError((err as any).response.data.message);
    }
    await getSaids();
  };

  const handleEditingButton = async () => {
    if (!isEditing) return setIsEditing((s) => !s);
    const transformAnimals = data.reduce((acc, animal) => {
      if (!animal.selected) return acc;
      const animalCopy = { ...animal };
      delete animalCopy.selected;

      return [...acc, { ...(animalCopy as IAnimal), type: ANIMAL_TYPE.PENDING } as IAnimal];
    }, [] as IAnimalTable[]);

    try {
      await Promise.all(transformAnimals.map((animal) => animalService.update(animal)));
      setIsEditing(false);
    } catch (err) {
      setError((err as any).message);
    }
    await getSaids();
  };

  const handleOnChange = (value: IAnimalTable) => {
    const prevState = data.find((d) => d.id === value.id);

    if (prevState?.startData !== value.startData)
      value.permanenceDate = dayjs(value.startData, 'YYYY-MM-DD').add(90, 'd').format('YYYY-MM-DD');
    else if (prevState?.permanenceDate !== value.permanenceDate)
      value.startData = dayjs(value.permanenceDate, 'YYYY-MM-DD').add(-90, 'd').format('YYYY-MM-DD');

    return value;
  };

  useEffect(() => {
    setData(said.animals);
  }, [said]);

  useEffect(() => {
    setError(undefined);
  }, [isEditing]);

  return (
    <Card type="ultra-wide">
      <HorizontalContainer className="pending-card-title">
        <HorizontalContainer className="paddocks-card-title">
          <h2>{said.name}</h2>
        </HorizontalContainer>
        <HorizontalContainer className="pending-movements-buttons">
          <Button onClick={handleSave}>{`Confirmar ${data.filter((x) => x.selected).length || ''}`}</Button>
          <Button onClick={handleEditingButton} className="secondary">
            {isEditing ? `Guardar ${data.filter((x) => x.selected).length || ''}` : 'Editar'}
          </Button>
          <RedButton>{`Eliminar ${data.filter((x) => x.selected).length || ''}`}</RedButton>
        </HorizontalContainer>
      </HorizontalContainer>
      {data.length ? (
        <EditableTable<IAnimalTable>
          data={data as unknown as IAnimalTable[]}
          isEditing={isEditing}
          columns={columns}
          setData={setData}
          handleSelect={handleSelect}
          onChange={handleOnChange}
          error={error}
        />
      ) : (
        <h2 className="empty-message">No hay datos</h2>
      )}
    </Card>
  );
};

export default PendingMovementsCard;
