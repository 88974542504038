import { FORM_INPUT_TYPE, IFormInput } from '../../../../types';
import GenericInput from '../../../inputs/GenericInput';
import TextAreaInput from '../../../inputs/TextArea';
import SelectInput from '../../../inputs/selectInput/SelectInput';

export interface Props {
  input: IFormInput;
}
const HandleInput: React.FC<Props> = ({ input }) => {
  const { type = FORM_INPUT_TYPE.TEXT } = input;
  return (
    <>
      {type === FORM_INPUT_TYPE.TEXT && <GenericInput key={input.id} {...input} />}
      {type === FORM_INPUT_TYPE.TEXT_AREA && <TextAreaInput key={input.id} {...input} />}
      {type === FORM_INPUT_TYPE.PASSWORD && <GenericInput key={input.id} {...input} />}
      {type === FORM_INPUT_TYPE.NUMBER && (
        <GenericInput key={input.id} {...input} className={`${input.className || ''} number-container`} />
      )}
      {type === FORM_INPUT_TYPE.URL && <GenericInput key={input.id} {...input} />}
      {type === FORM_INPUT_TYPE.SELECT_AND_QUANTITY && <GenericInput key={input.id} {...input} />}
      {type === FORM_INPUT_TYPE.DATE && <GenericInput key={input.id} {...input} />}
      {type === FORM_INPUT_TYPE.SELECT && (
        <SelectInput key={input.id} {...(input as unknown as IFormInput<HTMLSelectElement>)} />
      )}
    </>
  );
};

export default HandleInput;
