import { Link, useLocation } from 'react-router-dom';
import './LinkIcon.css';

export interface Props {
  text?: string;
  to?: string;
  icon?: string;
  activeclassName?: string;
  className?: string;
  activePath?: string;
}
const LinkIcon = ({ text, to = '', icon = '', activeclassName = '', className = '', activePath }: Props) => {
  const location = useLocation();
  return (
    <Link
      to={to}
      className={`link-icon ${className} ${
        (activePath && location.pathname.includes(activePath)) || location.pathname === to
          ? activeclassName
          : ''
      }`}
    >
      <img src={icon} alt="icon" />
      {text || ' '}
    </Link>
  );
};

export default LinkIcon;
