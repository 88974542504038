import React from 'react';
import { Link } from 'react-router-dom';
import { BUTTON_TYPE, IFormButton } from '../../../types';
import './Button.css';

export interface Props {
  text?: string;
  to?: string;
  icon?: string;
  activeclassName?: string;
  className?: string;
  children?: React.ReactNode;
}
const ButtonLink: React.FC<Props> = ({
  text,
  to = '',
  icon = '',
  activeclassName = '',
  className = '',
  children,
}: Props) => {
  return (
    <Link to={`${to}`} className={`button ${className}`}>
      {text}
      {children}
    </Link>
  );
};

export default ButtonLink;
