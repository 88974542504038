import FarmLayout from '../../components/farmLayout/FarmLayout';
import { useAppSelector } from '../../store';
import SaidSettings from './saidSettings/SaidSettings';
import UsersSettings from './userSettings/UsersSettings';
import './Settings.css';
import VerticalContainer from '../../components/containers/verticalContainer/VerticalContainer';
import HorizontalContainer from '../../components/horizontalContainer/HorizontalContainer';
import ColumnsSettings from './columnsSettings/ColumnsSettings';

const Settings = () => {
  const farm = useAppSelector((s) => s.farm);
  return (
    <FarmLayout
      className="dashboard paddocks settings"
      title={farm?.name || ''}
      description="Categorias"
      headerChildren={<h2>Ajustes</h2>}
    >
      <UsersSettings />
      <HorizontalContainer className="settings-inline-margins">
        <SaidSettings />
        <ColumnsSettings />
      </HorizontalContainer>
    </FarmLayout>
  );
};

export default Settings;
