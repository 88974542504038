import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../components/containers/card/Card';
import CenterContainer from '../../../components/containers/centeredContainer/CenterContainer';
import FarmLayout from '../../../components/farmLayout/FarmLayout';
import StepForm from '../../../components/generic/stepForm/StepForm';
import TitleSubTitle from '../../../components/generic/TitleSubTitle';
import { subCategoryService } from '../../../service/subCategory.service';
import { ICategories } from '../../../types';
import { addPaddockForm } from './AddPaddock.form';

const AddPaddock = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [stepIndex, setStepIndex] = useState(0);
  const { form, setOptions } = addPaddockForm(navigate, id || -1);

  const handleForm = async () => {
    const subCategories = await subCategoryService.getAll({ farmId: (id || -2).toString() });
    const selectOptions = subCategories.data.map((sub) => ({ value: sub.id, label: sub.name }));
    setOptions(selectOptions);
  };

  useEffect(() => {
    handleForm();
  }, []);
  return (
    <FarmLayout>
      <CenterContainer className="add-paddock-container">
        <Card type="wide" className="center">
          <TitleSubTitle
            text="Complete los siguientes pasos para crear un potrero"
            title="Agregar Potrero"
            isShowSeparator={true}
          />
          {form.length ? (
            <StepForm<ICategories> steps={form} stepIndex={stepIndex} setStepIndex={setStepIndex} />
          ) : (
            <></>
          )}
        </Card>
      </CenterContainer>
    </FarmLayout>
  );
};

export default AddPaddock;
