import { useEffect, useState } from 'react';
import Card from '../../../components/containers/card/Card';
import VerticalContainer from '../../../components/containers/verticalContainer/VerticalContainer';
import HorizontalContainer from '../../../components/horizontalContainer/HorizontalContainer';
import Button from '../../../components/inputs/buttons/Button';
import RedButton from '../../../components/inputs/buttons/Redbutton';
import ErrorMessage from '../../../components/inputs/errorMessage/ErrorMessage';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import Table from '../../../components/table/EditableTable';
import { saidService } from '../../../service';
import { FORM_INPUT_TYPE, ISaid, ITableColumn } from '../../../types';
import { axiosHelper } from '../../../utils';
import AddSaids from './AddSaids';
import './SaidSettings.css';

const SaidSettings = () => {
  const [saids, setSaids] = useState<Partial<ISaid>[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState<Partial<ISaid>>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const columns = [
    { displayName: 'Nombre', key: 'name', type: FORM_INPUT_TYPE.TEXT },
    {
      displayName: 'Identificador',
      key: 'serial',
      type: FORM_INPUT_TYPE.TEXT,
      defaultValue: '',
    },
  ];
  const getSaids = async () => {
    const { data } = await saidService.getAll();
    setSaids(data);
  };

  const handleSelectDelete = async (id: string | number) => {
    setSelected(saids.find((said) => said.id == id));
  };

  const handleDelete = async () => {
    if (!selected || !selected.id) return;
    try {
      await saidService.delete(selected.id);
      setSelected(undefined);
    } catch (e: unknown) {
      setErrorMessage(axiosHelper.getErrorMessage(e));
    } finally {
      getSaids();
    }
  };

  useEffect(() => {
    getSaids();
  }, []);
  return (
    <>
      <AddSaids showModal={showModal} setShowModal={setShowModal} getSaids={getSaids} />
      <VerticalContainer className="said-settings">
        <Card>
          <HorizontalContainer className="title">
            <h2>Diocses</h2>
            <Button onClick={() => setShowModal(true)}>Agregar</Button>
          </HorizontalContainer>
          <Table<ISaid>
            data={saids as ISaid[]}
            columns={columns as ITableColumn<ISaid>[]}
            setData={setSaids as React.Dispatch<React.SetStateAction<ISaid[]>>}
            handleDelete={handleSelectDelete}
            isDeleteAlwaysOn={true}
          ></Table>
        </Card>
      </VerticalContainer>

      <ConfirmModal
        text={`¿Estás seguro que deseas eliminar este Dicose ${selected?.name}?`}
        title={`Eliminar Dicose ${selected?.name}`}
        showModal={!!selected}
      >
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <HorizontalContainer>
          <Button onClick={handleDelete}>Confirmar</Button>
          <RedButton onClick={() => setSelected(undefined)}>Cancelar</RedButton>
        </HorizontalContainer>
      </ConfirmModal>
    </>
  );
};

export default SaidSettings;
