export enum MOVEMENTS_TYPE {
  ACTIVE = '0',
  PENDING = '1',
  SALE = '2',
  DEAD = '3',
  LOST = '4',
  TRANSFER = '5',
  BOUGHT = '6',
  BIRTH = '7',
}

export const movementsOptions = [
  {
    label: 'Transferir',
    value: MOVEMENTS_TYPE.TRANSFER,
  },
  {
    label: 'Venta',
    value: MOVEMENTS_TYPE.SALE,
  },
  {
    label: 'COMPRA',
    value: MOVEMENTS_TYPE.BOUGHT,
  },
  {
    label: 'Nacimiento',
    value: MOVEMENTS_TYPE.BIRTH,
  },
  {
    label: 'MUERTE',
    value: MOVEMENTS_TYPE.DEAD,
  },
  {
    label: 'PERDIDA',
    value: MOVEMENTS_TYPE.LOST,
  },
];

export const showOriginMovements = [
  MOVEMENTS_TYPE.SALE,
  MOVEMENTS_TYPE.LOST,
  MOVEMENTS_TYPE.DEAD,
  MOVEMENTS_TYPE.TRANSFER,
];

export const showDestinyMovements = [MOVEMENTS_TYPE.BIRTH, MOVEMENTS_TYPE.BOUGHT];

export enum OBSERVATIONS_ACTIONS_ENUM {
  DUPLICATE = 'DUPLICATE',
  TRANSFER = 'TRANSFER',
  DELETE = 'DELETE',
}

export const originalObservations = [
  {
    value: OBSERVATIONS_ACTIONS_ENUM.DUPLICATE,
    label: 'Duplicar',
    isActive: [MOVEMENTS_TYPE.TRANSFER],
  },
  {
    value: OBSERVATIONS_ACTIONS_ENUM.TRANSFER,
    label: 'Transferir',
    isActive: [MOVEMENTS_TYPE.TRANSFER],
  },
  {
    value: OBSERVATIONS_ACTIONS_ENUM.DELETE,
    label: 'Eliminar',
    isActive: [
      MOVEMENTS_TYPE.TRANSFER,
      MOVEMENTS_TYPE.SALE,
      MOVEMENTS_TYPE.DEAD,
      MOVEMENTS_TYPE.TRANSFER,
      MOVEMENTS_TYPE.LOST,
    ],
  },
];
