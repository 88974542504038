import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../../components/containers/card/Card';
import CardList from '../../../components/containers/card/CardList';
import FarmLayout from '../../../components/farmLayout/FarmLayout';
import HorizontalContainer from '../../../components/horizontalContainer/HorizontalContainer';
import Button from '../../../components/inputs/buttons/Button';
import LinkButton from '../../../components/inputs/buttons/LinkButton';
import RedButton from '../../../components/inputs/buttons/Redbutton';
import ErrorMessage from '../../../components/inputs/errorMessage/ErrorMessage';
import GenericInput from '../../../components/inputs/GenericInput';
import { animalColumnService, observationService, paddockService, saidService } from '../../../service';
import { animalService } from '../../../service/animal.service';
import { subCategoryService } from '../../../service/subCategory.service';
import { useAppSelector } from '../../../store';
import {
  FORM_INPUT_TYPE,
  IAnimal,
  IAnimalColumn,
  IPaddock,
  ISubCategory,
  ITableColumn,
} from '../../../types';
import {
  MOVEMENTS_TYPE,
  OBSERVATIONS_ACTIONS_ENUM,
  showOriginMovements,
} from '../../../types/movements.types';
import { functionHelper } from '../../../utils';
import { paddockHelper } from '../../farms/paddocks/paddock.helper';
import './CreateMovements.css';
import MovementEntry from './MovementsEntry';
import MovementObservations from './MovementsObservations';
import MovementsOptions from './MovementsOptions';

const CreateMovements = () => {
  const { id } = useParams();
  const farm = useAppSelector((state) => state.farm);
  const [categories, setCategories] = useState<ISubCategory[]>([]);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [paddocks, setPaddocks] = useState<IPaddock[]>([]);
  const [_activeColumns, setActiveColumns] = useState<IAnimalColumn[]>([]);
  const [selectedPaddockOrigin, setSelectedPaddockOrigin] = useState<IPaddock>();
  const [selectedPaddockDestiny, setSelectedPaddockDestiny] = useState<IPaddock>();
  const [columns, setColumns] = useState<ITableColumn<IAnimal>[]>([]);
  const [animalsToMove, setAnimalsToMove] = useState<{
    [key: string]: { quantity: number; soldPirce?: number };
  }>({});
  const [observationsToChange, setObservationsToChange] = useState<{
    [id: string]: OBSERVATIONS_ACTIONS_ENUM;
  }>({});
  const [operationType, setOperationType] = useState<MOVEMENTS_TYPE>();
  const getPaddocks = async () => {
    if (!id) return;
    const { data } = await paddockService.getAll({ farmId: id });
    setPaddocks(data);
  };

  const getAnimalColumns = async () => {
    const { data } = await animalColumnService.getAll();
    const { data: categories } = await subCategoryService.getAll({ farmId: id });
    const { data: saids } = await saidService.getAll({ farmId: id });

    setCategories(categories);
    setColumns(
      paddockHelper.getColumns(
        categories.map((c) => ({ value: c.id, label: c.name })),
        saids.map((s) => ({ value: String(s.id), label: s.name })),
        data,
      ),
    );
    setActiveColumns(data);
  };

  const handleBack = () => {
    navigate(`/farm/${id}/paddock`);
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    if (Number(value) >= 1)
      return setAnimalsToMove((perv) => ({
        ...perv,
        [id]: { ...(perv[id] || {}), quantity: Number(value) },
      }));
  };

  const handleSoldPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    if (Number(value) > 0)
      return setAnimalsToMove((perv) => ({
        ...perv,
        [id]: { ...(perv[id] || {}), soldPirce: Number(value) },
      }));
  };

  const hanldeSubmit = async () => {
    if (!selectedPaddockOrigin) return;
    setError(null);

    const ids = Object.keys(animalsToMove);
    if (ids.length <= 0) return;
    const animalsToUpdate: Partial<IAnimal>[] = ids.map((id) => {
      const animal = selectedPaddockOrigin?.animals?.find((a) => a.id === Number(id));
      return {
        id: animal?.id,
        paddockId: selectedPaddockDestiny?.id || selectedPaddockOrigin?.id,
        ...animalsToMove[id],
        type: operationType,
      };
    });
    try {
      Object.entries(observationsToChange).map(([id, action]) => {
        if (action === OBSERVATIONS_ACTIONS_ENUM.DELETE)
          return observationService.delete(id, selectedPaddockOrigin.id);
        else if (selectedPaddockDestiny && action === OBSERVATIONS_ACTIONS_ENUM.TRANSFER)
          return observationService.update(
            { id, paddockId: selectedPaddockDestiny.id },
            selectedPaddockDestiny.id,
          );
        else if (selectedPaddockDestiny && action === OBSERVATIONS_ACTIONS_ENUM.DUPLICATE) {
          const observationToDuplicate = selectedPaddockOrigin?.observations?.find(
            (o) => o.id === Number(id),
          );
          if (observationToDuplicate)
            return observationService.create({
              ...observationToDuplicate,
              paddockId: selectedPaddockDestiny.id,
            });
        }
      });
      await Promise.all(animalsToUpdate.map(async (animal) => animalService.update(animal)));
      handleBack();
    } catch (err: AxiosError<{ message: string }> | unknown) {
      if (err instanceof AxiosError<{ message: string }>)
        setError(err?.message ? err.response?.data?.message : 'occurio un error inesperado');
      else setError('occurio un error inesperado');
    }
  };

  useEffect(() => {
    getPaddocks();
  }, [id]);

  useEffect(() => {
    getAnimalColumns();
  }, []);

  return (
    <FarmLayout
      className="dashboard paddocks create-movements"
      title={farm?.name || ''}
      description="Categorias"
      headerChildren={
        <>
          <h2 className="white-font"> Crear Movimientos</h2>
          <LinkButton className="create-movement-link" to={`/farm/${id}/movements/Pending`}>
            Ver Pendientes
          </LinkButton>
        </>
      }
    >
      <CardList className={'farm-dashboard-list create-moevements-list'}>
        <MovementsOptions
          operationType={operationType}
          setOperationType={setOperationType}
          paddocks={paddocks}
          setSelectedPaddockOrigin={setSelectedPaddockOrigin}
          setSelectedPaddockDestiny={setSelectedPaddockDestiny}
        />

        {operationType && selectedPaddockOrigin && showOriginMovements.includes(operationType) && (
          <Card type="ultra-wide" className="movements-paddock-table">
            <HorizontalContainer>
              <HorizontalContainer className="paddocks-card-title">
                <h2>{selectedPaddockOrigin?.name}</h2>
              </HorizontalContainer>
            </HorizontalContainer>
            <div className="paddock-table-container">
              {error && <ErrorMessage>{error}</ErrorMessage>}
              {selectedPaddockOrigin?.animals && (
                <table>
                  <thead>
                    <tr>
                      {columns.map((c) => (
                        <th>{c.displayName}</th>
                      ))}
                      <th>Cantidad a Mover</th>
                      {operationType == MOVEMENTS_TYPE.SALE ? <th>Precio Venta</th> : <></>}
                    </tr>
                  </thead>
                  <tbody>
                    {selectedPaddockOrigin?.animals.map((a) => (
                      <tr>
                        {columns.map((c) => (
                          <td>{functionHelper.getValueRecusive(a, c.keyPath || c.key, c.defaultValue)}</td>
                        ))}
                        <td>
                          <GenericInput
                            id={a.id.toString()}
                            type={FORM_INPUT_TYPE.NUMBER}
                            placeholder="0"
                            min={0}
                            max={a.quantity}
                            onChange={handleQuantityChange}
                          />
                        </td>
                        {operationType == MOVEMENTS_TYPE.SALE ? (
                          <td>
                            <GenericInput
                              id={a.id.toString()}
                              type={FORM_INPUT_TYPE.NUMBER}
                              placeholder="0"
                              onChange={handleSoldPriceChange}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <MovementObservations
              observations={selectedPaddockOrigin.observations}
              operationsType={operationType}
              setObservationsToChange={setObservationsToChange}
            />
            <HorizontalContainer className="button-container">
              <RedButton className="" onClick={handleBack}>
                Cancelar
              </RedButton>
              <Button className="secondary" onClick={hanldeSubmit}>
                Confirmar
              </Button>
            </HorizontalContainer>
          </Card>
        )}

        <MovementEntry
          paddock={selectedPaddockDestiny}
          columns={columns}
          getPaddocks={getPaddocks}
          handleBack={handleBack}
          operationType={operationType}
        />
      </CardList>
    </FarmLayout>
  );
};

export default CreateMovements;
