import { IAnimalColumn } from './../types/entities.types';
import BaseService from './base.service';
import { ENDPOINTS } from './endpoints';

class AnimalColumnService extends BaseService<IAnimalColumn> {
  constructor() {
    super(ENDPOINTS.ANIMAL_COLUMNS);
  }
}

export const animalColumnService = new AnimalColumnService();
