import { IGeneralSlice } from './../types/store.types';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IGeneralSlice = JSON.parse(localStorage.getItem('general') || 'null');

const generalSlice = createSlice({
  name: 'general',
  initialState: initialState || { error: '', isLoading: false, rememberMe: false, isLoadingDisabled: false },
  reducers: {
    setPartial: (state, action) => {
      const newState = { ...state, ...action.payload };
      localStorage.setItem('general', JSON.stringify(newState));
      return newState;
    },
    clear: (state, action) => {
      const newState = {} as IGeneralSlice;
      localStorage.removeItem('general');
      return newState;
    },
    setLoading: (state, action) => {
      const newState = { ...state, isLoading: action.payload };
      localStorage.setItem('general', JSON.stringify(newState));
      return newState;
    },
    setDisabledLoading: (state, action) => {
      const newState = { ...state, isLoadingDisabled: action.payload };
      localStorage.setItem('general', JSON.stringify(newState));
      return newState;
    },
  },
});

export const generalActions = generalSlice.actions;
export default generalSlice;
