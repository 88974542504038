import { IFormInput } from '../../types';
import HandleInput from '../generic/form/inputs/HandleInput';

export interface Props {
  input: IFormInput;
}

const TableInput: React.FC<Props> = ({ input }) => {
  return <HandleInput input={{ ...input, className: `table-input ${input.className || ''}` }} />;
};

export default TableInput;
