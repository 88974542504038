import './Card.css';

export interface Props {
  children?: React.ReactNode;
  childrenAfterContent?: React.ReactNode;
  className?: string;
  type?: 'wide' | 'normal' | '' | 'ultra-wide';
  onClick?: () => void;
}
const Card = ({ children, className = '', type = '', childrenAfterContent, onClick }: Props) => {
  return (
    <div className={`main-card ${type} ${className}`} onClick={onClick}>
      <div className="content">{children}</div>
      {childrenAfterContent}
    </div>
  );
};

export default Card;
