import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { authService } from '../../service';
import { useAppDispatch, userActions } from '../../store';

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const handleToken = async () => {
    if (!token) return navigate('/login');
    try {
      const user = await authService.validateToken(token);
      if (!user) return navigate('/login');
      navigate('/register', { state: { step: 3 } });
    } catch (err) {
      navigate('/login');
    }
  };

  useQuery('users', handleToken);
  return <></>;
};

export default VerifyEmail;
