import { addIcon } from '../../images/icons';
import { FORM_INPUT_TYPE, ITableColumn } from '../../types';
import IconButton from '../inputs/buttons/IconButton';
import EditableRow from './EditableRow';
import './Table.css';

export interface Props<T> {
  data: T[];
  columns?: ITableColumn<T>[];
  isEditing?: boolean;
  setData: React.Dispatch<React.SetStateAction<T[]>>;
  handleDelete?: (id: string | number) => void;
  handleSelect?: (id: string | number, selected?: boolean) => void;
  addText?: string;
  error?: string;
  isDeleteAlwaysOn?: boolean;
  onChange?: (elem: T) => T | void;
  isHiddenHeaders?: boolean;
  className?: string;
}
const Table = <T extends { id: string | number; selected?: boolean }>({
  data,
  setData,
  columns = [],
  isEditing,
  handleDelete,
  handleSelect,
  addText,
  error,
  isDeleteAlwaysOn,
  onChange,
  isHiddenHeaders = false,
  className = '',
}: Props<T>) => {
  const handleChange = (elem: T) => {
    const value = onChange ? onChange(elem) : elem;
    setData((d) => {
      const index = d.findIndex((e) => e.id === elem.id);
      d[index] = value || elem;
      return d;
    });
    return value;
  };
  const displayColumns = columns.length
    ? columns
    : columns.map(({ key }) => ({
        displayName: key,
        key: key as keyof T,
        type: FORM_INPUT_TYPE.TEXT,
        options: [],
      }));
  const handleAdd = () => {
    const newObj = columns
      .map(({ key }) => key)
      .reduce((acc, key) => {
        const objs = { ...acc, [key]: '' };
        return objs;
      }, {} as T);
    setData((d) => [...d, { ...newObj, id: -(1 + data.length) } as T]);
  };

  return (
    <>
      <table className={isEditing ? 'is-editing ' : '' + className}>
        <thead hidden={isHiddenHeaders}>
          <tr>
            {displayColumns.map((column, index) => (
              <th key={`${column} ${index}`}>{column.displayName as string}</th>
            ))}
            {handleDelete && isEditing && <th className="isediting-header" />}
            {handleSelect && isEditing && <th className="isediting-header" />}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <EditableRow<T>
              key={`${row.id} ${index}`}
              row={row}
              isEditing={isEditing}
              onChange={handleChange}
              displayColumns={displayColumns || []}
              handleDelete={handleDelete}
              isDeleteAlwaysOn={isDeleteAlwaysOn}
              handleSelect={handleSelect}
            />
          ))}
        </tbody>
      </table>
      {error && isEditing && <h5 className="error-message">{error}</h5>}
      {isEditing && addText && (
        <IconButton className="paddock-icon-container" onClick={handleAdd}>
          <img src={addIcon} alt="add icon" />
          {addText}
        </IconButton>
      )}
    </>
  );
};

export default Table;
