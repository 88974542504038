import { useState } from 'react';
import { trashIcon } from '../../images/icons';
import { FORM_INPUT_TYPE, ITableColumn } from '../../types';
import Button from '../inputs/buttons/Button';
import CheckBoxInput from '../inputs/checkbox/CheckBoxInput';
import HandleCell from './HandleCell';

export interface Props<T> {
  row: T;
  displayColumns?:
    | ITableColumn<T>[]
    | {
        displayName: keyof T;
        key: keyof T;
        type: FORM_INPUT_TYPE;
        options: never[];
        isValidValue?: (value: any) => boolean;
      }[];
  isEditing?: boolean;
  handleDelete?: (id: string | number) => void;
  handleSelect?: (id: string | number, selected?: boolean) => void;
  error?: string;
  isDeleteAlwaysOn?: boolean;
  onChange?: (elem: T) => T | void;
  isHiddenHeaders?: boolean;
  className?: string;
}
const EditableRow = <T extends { id: string | number; selected?: boolean }>({
  row,
  displayColumns = [],
  isEditing,
  handleDelete,
  handleSelect,
  isDeleteAlwaysOn,
  onChange,
  className = '',
}: Props<T>) => {
  const [data, setData] = useState<T>(row);
  const handleCheckBox: React.MouseEventHandler<HTMLInputElement> = (e) => {
    handleSelect && handleSelect(e.currentTarget.id);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.id) return;
    const { id } = e.target;
    const field = id.split('_')[0] as keyof T;
    const transformedData = { ...data, [field]: e.target.value };
    if (!onChange) {
      setData({ ...transformedData });
      return;
    }

    const newData = onChange(transformedData);
    setData(newData || transformedData);
  };

  const handleClassName = () => {
    let className = '';
    displayColumns.forEach((column) => {
      const value = row[column.key];
      if (column.isValidValue && value) className = column.isValidValue(value as string) ? '' : 'error';
    });
    return className;
  };
  return (
    <tr className={`${className} ${handleClassName()}`}>
      {displayColumns.map((column, index) => (
        <HandleCell
          key={`${column} ${index} ${row[column.key]}`}
          column={column as ITableColumn<T>}
          row={data}
          isEditing={!!isEditing}
          index={index}
          onChange={handleChange}
        />
      ))}
      {handleDelete && (isDeleteAlwaysOn || isEditing) && (
        <td>
          <div className="isediting-col">
            <Button onClick={() => handleDelete(row.id)}>
              <img src={trashIcon} alt="trash-icon" />
            </Button>
          </div>
        </td>
      )}

      {handleSelect && isEditing && (
        <td>
          <div className="isediting-col">
            <CheckBoxInput
              label=""
              id={row.id.toString()}
              type="checkbox"
              onClick={handleCheckBox}
              checked={row.selected}
            />
          </div>
        </td>
      )}
    </tr>
  );
};

export default EditableRow;
