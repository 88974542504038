import { useEffect, useState } from 'react';
import { CowsRegister } from '../../../images';
import { useAppDispatch } from '../../../store';
import generalSlice from '../../../store/general.slice';
import CenterContainer from '../centeredContainer/CenterContainer';
import './CardWithImage.css';

export interface Props {
  children?: React.ReactNode;
  img?: string;
  className?: string;
}
const CardWithImage: React.FC<Props> = ({ children, img, className }) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const onLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    dispatch(generalSlice.actions.setLoading(isLoading));
    dispatch(generalSlice.actions.setLoading(false));
  }, [isLoading]);
  return (
    <CenterContainer className={className}>
      <div className="card-with-image">
        <div className="content">{children}</div>
        <img src={img || CowsRegister} alt="cows on a farm" onLoad={onLoad} />
      </div>
    </CenterContainer>
  );
};

export default CardWithImage;
