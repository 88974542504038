import { axios } from '../handlers';
import { IFarm, IFile } from './../types/entities.types';
import BaseService from './base.service';
import { ENDPOINTS } from './endpoints';

export class FarmService extends BaseService<IFarm> {
  constructor() {
    super(ENDPOINTS.FARMS);
  }

  async download(id: string | number): Promise<{ data: IFile }> {
    return (await axios.post(`${this.endpoint()}/download`, { farmId: id }, { withCredentials: true })).data;
  }
}

export const farmService = new FarmService();
