export const API =
  process.env.NODE_ENV === 'development' ? 'http://localhost:10' : 'https://api.ranchitapp.com';
export const ENDPOINTS = {
  AUTH: '/auth',
  USERS: () => '/users',
  CONTACT_US: () => '/contact-us',
  FARMS: () => '/farms',
  USER_FARMS: () => `farms/${location.pathname.match(/\/farm\/(\d+)\//)?.[1]}/users`,
  CATEGORIES: () => `farms/${location.pathname.match(/\/farm\/(\d+)\//)?.[1]}/categories`,
  SUB_CATEGORIES: () => `farms/${location.pathname.match(/\/farm\/(\d+)\//)?.[1]}/sub-categories`,
  PADDOCKS: () => `farms/${location.pathname.match(/\/farm\/(\d+)\//)?.[1]}/paddocks`,
  ANIMALS: () => `farms/${location.pathname.match(/\/farm\/(\d+)\//)?.[1]}/animals`,
  SAIDS: () => `farms/${location.pathname.match(/\/farm\/(\d+)\//)?.[1]}/saids`,
  MOVEMENTS: () => `farms/${location.pathname.match(/\/farm\/(\d+)/)?.[1]}/movements`,
  ANIMAL_COLUMNS: () => `farms/${location.pathname.match(/\/farm\/(\d+)/)?.[1]}/animal-columns`,
  OBSERVATIONS: () => `${ENDPOINTS.PADDOCKS()}/:paddockId/observations`,
};
