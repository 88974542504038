import { useEffect } from 'react';
import HorizontalContainer from '../../horizontalContainer/HorizontalContainer';
import Button from '../../inputs/buttons/Button';
import Card from './Card';

interface Props {
  children?: React.ReactNode;
  className?: string;
  setPagination?: ({ page, totalPages, take }: { page: number; totalPages: number; take: number }) => void;
  pagination?: { page: number; totalPages: number; take: number };
}
const CardList = ({
  children = <></>,
  className = '',
  setPagination,
  pagination = { page: 0, totalPages: 0, take: 0 },
}: Props) => {
  const { page, totalPages, take } = pagination;
  return (
    <>
      <div className={`card-list ${className}`}>
        {children}
        {setPagination && (
          <HorizontalContainer className="card-list-page">
            <Button disabled={page <= 1} onClick={() => setPagination({ page: page - 1, totalPages, take })}>
              Anterior
            </Button>
            <h4>
              Pagina {page} de {totalPages}
            </h4>
            <Button
              disabled={page >= totalPages}
              onClick={() => setPagination({ page: page + 1, totalPages, take })}
            >
              Siguiente
            </Button>
          </HorizontalContainer>
        )}
      </div>
    </>
  );
};

export default CardList;
