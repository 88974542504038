import { useNavigate } from 'react-router-dom';
import VerticalContainer from '../../components/containers/verticalContainer/VerticalContainer';
import HorizontalContainer from '../../components/horizontalContainer/HorizontalContainer';
import Button from '../../components/inputs/buttons/Button';
import { ArrowLanding, Banner, LandingImg, RanchitPhone } from '../../images';
import './Landing.css';
import Card from '../../components/containers/card/Card';
import { farmsLanding, gate, paw } from '../../images/icons';
import Header from '../../components/header/Header';
import { useAppDispatch } from '../../store';
import generalSlice from '../../store/general.slice';
import { useEffect } from 'react';
const Landing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  dispatch(generalSlice.actions.setLoading(true));
  const onLoad = () => {
    dispatch(generalSlice.actions.setLoading(false));
  };

  const handleDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };

  const handleStart = () => {
    navigate('/login');
  };
  return (
    <VerticalContainer className="landing-container">
      <img src={LandingImg} alt="landing image" className="landing-img" onLoad={onLoad} />
      <Header />
      <div className="landing-content">
        <h1>RANCHIT</h1>
        <Button onClick={handleStart}>COMENZAR</Button>
        <img src={ArrowLanding} alt="arrow down" onClick={handleDown} />
      </div>
      <HorizontalContainer className="half-spliter first">
        <VerticalContainer>
          <h3>Gestioná tu campo</h3>
          <p>
            Administrá tu ganado y potreros de manera efectiva con nuestra aplicación de gestión. La
            plataforma te permite registrar y monitorear tus animales, controlar su alimentación y programar
            recordatorios para realizar revisiones de salud. También podés crear y administrar categorías para
            una mejor organización.
          </p>
        </VerticalContainer>
        <VerticalContainer>
          <h3>Bot de Whatsapp</h3>
          <p>
            Queremos simplificar tu gestión, por eso creamos un bot de Whatsapp que te permite enviar las
            caravanas de tus animales en múltiples formatos, como txt, csv, comprobante de pdf y xlsx.
            Nosotros nos encargamos de procesarlos por ti. Nuestro sistema escanea la información del
            comprobante y la guarda en una base de datos para su posterior análisis y organización.
          </p>
        </VerticalContainer>
      </HorizontalContainer>
      <HorizontalContainer className="half-spliter center">
        <VerticalContainer>
          <h3>Sobre la app</h3>
          <p>
            Nuestra aplicación nace de la visión de un desarrollador con la ilusión de transformar la
            ganadería, trasladándola a la era digital. Nos enfocamos en proporcionar herramientas poderosas y
            de fácil uso que te ayuden a administrar tus tierras y animales de manera más eficiente.
          </p>
          <p>
            Creemos que la tecnología puede marcar una gran diferencia en la forma en que se gestiona el
            campo. Por eso estamos comprometidos en proporcionar soluciones innovadoras que te ayuden a
            superar desafíos y a aprovechar al máximo el potencial de tu tierra.
          </p>
        </VerticalContainer>
        <VerticalContainer>
          <img src={RanchitPhone} alt="phone with app logo" />
        </VerticalContainer>
      </HorizontalContainer>
      <HorizontalContainer className="banner-container">
        <img src={Banner} alt="img banner " />
        <VerticalContainer>
          <h1>Revolucioná Tu Campo</h1>
          <br />
          <h3>¿Quieres formar parte de la revolución? ¡Únete a nuestra beta!</h3>
        </VerticalContainer>
      </HorizontalContainer>
      <HorizontalContainer className="landing-cards">
        <VerticalContainer>
          <Card>
            <img src={farmsLanding} alt="farms icon" />
            <p>Administrá tus establecimientos. Gestioná desde tus animales hasta los potrertos</p>
          </Card>
        </VerticalContainer>
        <VerticalContainer>
          <Card>
            <img src={paw} alt="farms icon" />
            <p>Mantené tus animales al día. Obtené estadisticas y controlá tu stok sin problemas</p>
          </Card>
        </VerticalContainer>
        <VerticalContainer>
          <Card>
            <img src={gate} alt="farms icon" />
            <p>Manejá tus potreros, agregá animales y categorizalos. Tené el control total de tu campo</p>
          </Card>
        </VerticalContainer>
      </HorizontalContainer>
    </VerticalContainer>
  );
};

export default Landing;
