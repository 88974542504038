import { IAnimal } from './../types/entities.types';
import BaseService from './base.service';
import { ENDPOINTS } from './endpoints';

export class AnimalService extends BaseService<IAnimal> {
  constructor() {
    super(ENDPOINTS.ANIMALS);
  }
}

export const animalService = new AnimalService();
