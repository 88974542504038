import { useEffect, useState } from 'react';
import VerticalContainer from '../../../components/containers/verticalContainer/VerticalContainer';
import HorizontalContainer from '../../../components/horizontalContainer/HorizontalContainer';
import SelectInput from '../../../components/inputs/selectInput/SelectInput';
import { FORM_INPUT_TYPE, IObservations } from '../../../types';
import {
  MOVEMENTS_TYPE,
  OBSERVATIONS_ACTIONS_ENUM,
  originalObservations,
} from '../../../types/movements.types';

interface IProps {
  observations?: IObservations[];
  operationsType?: MOVEMENTS_TYPE;
  setObservationsToChange: React.Dispatch<React.SetStateAction<{ [id: string]: OBSERVATIONS_ACTIONS_ENUM }>>;
}

const MovementObservations = ({ observations = [], operationsType, setObservationsToChange }: IProps) => {
  const [observationOptions, setObservationOptions] = useState(originalObservations);
  const defaultValue = 'Seleccione una opcion';

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { id, value } = e.target;
    if (defaultValue === value) {
      setObservationsToChange((x) => {
        delete x[id];
        return x;
      });
      return;
    }
    setObservationsToChange((x) => ({ ...x, [id]: value as OBSERVATIONS_ACTIONS_ENUM }));
  };

  useEffect(() => {
    const filteredOptions = originalObservations.filter(
      (x) => operationsType && x.isActive.includes(operationsType),
    );
    setObservationOptions(filteredOptions);
  }, [operationsType]);

  return (
    <VerticalContainer className="paddock-observations">
      <HorizontalContainer>
        <h3>Observaciones</h3>
        <h4 className="observations-movements">Seleccionar</h4>
      </HorizontalContainer>
      {observations?.map((observation) => (
        <HorizontalContainer key={observation.id} className="transfer-obseravation">
          <p>{observation.value}</p>

          <SelectInput
            defaultValue={defaultValue}
            id={observation.id.toString()}
            type={FORM_INPUT_TYPE.SELECT}
            options={observationOptions}
            onChange={handleSelectChange}
          />
        </HorizontalContainer>
      ))}
    </VerticalContainer>
  );
};

export default MovementObservations;
